export const determineSelectedProduct = (
  productCatalogue,
  selectedMusicLicenseId,
  selectedColorId,
  selectedSubscriptionTemplateId
) => {
  const availableProducts = productCatalogue.filter((product) => {
    const musicLicenseTest = product.musicLicense.id === selectedMusicLicenseId;
    const colorTest = product.color.id === selectedColorId;
    const subscriptionTemplateTest = product.subscriptionTemplate.id === selectedSubscriptionTemplateId;

    return colorTest && musicLicenseTest && subscriptionTemplateTest;
  });

  if (availableProducts.length === 0) {
    throw new Error(
      [
        `Did not find an available product for musicLicenseId`,
        `[${selectedMusicLicenseId}], colorId [${selectedColorId}] and`,
        `subscriptionTemplateId [${selectedSubscriptionTemplateId}]`,
      ].join(' ')
    );
  }

  if (availableProducts.length > 1) {
    throw new Error(
      [
        `Found multiple [${availableProducts.length}] products for musicLicenseId`,
        `[${selectedMusicLicenseId}], colorId [${selectedColorId}] and`,
        `subscriptionTemplateId [${selectedSubscriptionTemplateId}]`,
      ].join(' ')
    );
  }

  return availableProducts[0];
};
