import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { Modal, determineAvailableMusicLicenses } from '../../../../../common';
import {
  GET_ZONES,
  GET_MUSIC_REGIONS,
  CREATE_MUSIC_PROFILE,
  GET_ALL_PRODUCTS,
  GET_ALL_INDUSTRIES,
  CHANGE_MUSIC_PROFILE_OF_ZONES,
} from './api';
import {
  useUpdateMusicProfileCacheAfterCreate,
  updateZonesCacheAfterMutation,
} from '../../cache';
import ConfigureStep from './configureStep';
import { ZonesStep } from './zonesStep';

const steps = ['configure', 'zones'];

const ConfigureMusicProfileModal = ({
  onClose,
  isOpen,
  setProfile,
  profile,
}) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(steps[0]);
  const [selectedZones, setSelectedZones] = useState([profile]); // This modal only opens when profile is actually a zone, naming here can be confusing
  const [regionId, setRegionId] = useState('');
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [industryId, setIndustryId] = useState('');

  const {
    error: zonesError,
    data: zonesData,
    loading: loadingZones,
  } = useQuery(GET_ZONES);

  const {
    loading: loadingProducts,
    data: dataProducts,
    error: errorProducts,
  } = useQuery(GET_ALL_PRODUCTS);

  const musicLicenses = useMemo(
    () =>
      dataProducts?.allProducts?.length > 0
        ? determineAvailableMusicLicenses(dataProducts.allProducts, true)
        : [],
    [dataProducts?.allProducts]
  );

  const {
    loading: loadingRegions,
    data: regionsData,
    error: errorRegions,
  } = useQuery(GET_MUSIC_REGIONS);
  const {
    loading: loadingIndustries,
    data: industriesData,
    error: errorIndustries,
  } = useQuery(GET_ALL_INDUSTRIES);

  const handleUpdateMusicProfileCacheAfterCreate =
    useUpdateMusicProfileCacheAfterCreate();

  const [
    changeMusicProfileOfZones,
    {
      loading: loadingChangeMusicProfileOfZones,
      error: errorChangeMusicProfileOfZones,
    },
  ] = useMutation(CHANGE_MUSIC_PROFILE_OF_ZONES, {
    update: updateZonesCacheAfterMutation,
    onCompleted: () => {
      setSelectedZones([]);
      onClose();
    },
  });

  const [
    createMusicProfile,
    { loading: loadingCreateMusicProfile, error: errorCreateMusicProfile },
  ] = useMutation(CREATE_MUSIC_PROFILE, {
    update: handleUpdateMusicProfileCacheAfterCreate,
    onCompleted: (data) => {
      // add selected zones to newly created zone and select the new profile
      const normalizedEntries = selectedZones
        .filter((changedZone) => Boolean(changedZone?.id))
        .map((changedZone) => ({
          zoneId: changedZone.id,
          musicProfileId:
            data?.createMusicProfile?.id !== '-1'
              ? data.createMusicProfile.id
              : null,
        }));
      changeMusicProfileOfZones({ variables: { entries: normalizedEntries } });
      setProfile(data?.createMusicProfile?.id);
    },
  });

  const handleGoBack = useCallback(() => {
    const currentIndex = steps.indexOf(step);
    if (currentIndex < 1) {
      onClose();
    } else {
      setStep(steps[currentIndex - 1]);
    }
  }, [step, onClose]);

  const handleGoNext = useCallback(() => {
    const currentIndex = steps.indexOf(step);
    if (step === steps[steps.length - 1]) {
      createMusicProfile({
        variables: {
          name,
          musicRegionId: regionId,
          musicLicenseId: categoryId,
        },
      });
    } else {
      setStep(steps[currentIndex + 1]);
    }
  }, [step, createMusicProfile, name, regionId, categoryId]);

  const handleSubmitZones = useCallback(
    (zones) => {
      setSelectedZones(zones);
      handleGoNext();
    },
    [handleGoNext]
  );

  const regions = useMemo(
    () => regionsData?.musicRegions ?? [],
    [regionsData?.musicRegions]
  );

  const industries = useMemo(
    () => industriesData?.industries ?? [],
    [industriesData?.industries]
  );

  const zones = useMemo(() => zonesData?.zones ?? [], [zonesData?.zones]);

  return (
    <Modal
      footer={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('musicManagement:configureMusicProfile.title')}
    >
      {step === steps[0] && (
        <ConfigureStep
          categoryId={categoryId}
          error={errorProducts || errorIndustries || errorRegions}
          industries={industries}
          industryId={industryId}
          loadingIndustries={loadingIndustries}
          loadingMusicLicenses={loadingProducts}
          loadingRegions={loadingRegions}
          musicLicenses={musicLicenses}
          name={name}
          onClose={onClose}
          onNext={handleGoNext}
          regionId={regionId}
          regions={regions}
          setCategoryId={setCategoryId}
          setIndustryId={setIndustryId}
          setName={setName}
          setRegionId={setRegionId}
        />
      )}
      {step === steps[1] && (
        <ZonesStep
          error={
            zonesError ||
            errorCreateMusicProfile ||
            errorChangeMusicProfileOfZones
          }
          goBack={handleGoBack}
          loading={loadingZones}
          loadingCreateMusicProfile={
            loadingCreateMusicProfile || loadingChangeMusicProfileOfZones
          }
          name={name}
          onSubmit={handleSubmitZones}
          selectedZones={selectedZones}
          zones={zones}
        />
      )}
    </Modal>
  );
};

export default ConfigureMusicProfileModal;
