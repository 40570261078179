export const determineAvailableColors = (productCatalogue, selectedMusicLicenseId, userWantsForeverProduct) => {
  const availableProducts = productCatalogue.filter((product) => product.isForever === userWantsForeverProduct);

  const productsWithSelectedMusicLicenseId = availableProducts.filter(
    (product) => product.musicLicense.id === selectedMusicLicenseId
  );

  const availableColorsById = productsWithSelectedMusicLicenseId.reduce((acc, curr) => {
    if (!acc[curr.color.id]) {
      return { ...acc, [curr.color.id]: curr.color };
    }
    return acc;
  }, {});

  const availableColors = Object.keys(availableColorsById).map((colorId) => availableColorsById[colorId]);

  return availableColors;
};
