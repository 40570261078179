import styled from 'styled-components';

import AddonsTable from './addonsTableCard';
import { PageHeader } from './pageHeader';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;
  height: 100%;
`;

const Overview = () => {
  return (
    <Container>
      <PageHeader />
      <AddonsTable />
    </Container>
  );
};

export default Overview;
