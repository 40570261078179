import { determineSelectedProduct } from './determineSelectedProduct';

export const calculateSavingsPerYearForSubscriptionTemplate = (
  subscriptionTemplateId,
  productCatalogue,
  selectedMusicLicenseId,
  selectedColorId
) => {
  const availableProducts = productCatalogue.filter(
    (product) => product.musicLicense.id === selectedMusicLicenseId && product.color.id === selectedColorId
  );

  const productPricesPerYear = availableProducts.map(
    (product) => (product.price / product.subscriptionTemplate.recurringInterval) * 12
  );

  const expensivestPricePerYear = productPricesPerYear.reduce((acc, curr) => (curr > acc ? curr : acc));

  const product = determineSelectedProduct(
    productCatalogue,
    selectedMusicLicenseId,
    selectedColorId,
    subscriptionTemplateId
  );

  const productPricePerYear = (product.price / product.subscriptionTemplate.recurringInterval) * 12;

  return expensivestPricePerYear - productPricePerYear;
};
