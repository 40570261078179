import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { Modal, determineAvailableMusicLicenses } from '../../../../../common';
import DetailsStep from './detailsStep';
import CategoryStep from './categoryStep';
import { ZonesStep } from './zonesStep';
import {
  GET_ZONES,
  GET_MUSIC_REGIONS,
  CREATE_MUSIC_PROFILE,
  GET_ALL_PRODUCTS,
  CHANGE_MUSIC_PROFILE_OF_ZONES,
} from './api';
import {
  useUpdateMusicProfileCacheAfterCreate,
  updateZonesCacheAfterMutation,
} from '../../cache';

const steps = ['category', 'details', 'zones'];

const AddMusicProfileModal = ({ onClose, isOpen, setProfileId }) => {
  const { t } = useTranslation();

  const [step, setStep] = useState(steps[0]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [regionId, setRegionId] = useState('');
  const [name, setName] = useState('');
  const [categoryId, setCategoryId] = useState('');

  const {
    error: zonesError,
    data: zonesData,
    loading: loadingZones,
  } = useQuery(GET_ZONES);

  const {
    loading: loadingProducts,
    data: dataProducts,
    error: errorProducts,
  } = useQuery(GET_ALL_PRODUCTS);

  const musicLicenses = useMemo(
    () =>
      dataProducts?.allProducts?.length > 0
        ? determineAvailableMusicLicenses(dataProducts.allProducts, true)
        : [],
    [dataProducts?.allProducts]
  );

  const {
    loading: loadingRegions,
    data: regionsData,
    error: errorRegions,
  } = useQuery(GET_MUSIC_REGIONS);

  const handleUpdateMusicProfileCacheAfterCreate =
    useUpdateMusicProfileCacheAfterCreate();

  const [
    changeMusicProfileOfZones,
    {
      loading: loadingChangeMusicProfileOfZones,
      error: errorChangeMusicProfileOfZones,
    },
  ] = useMutation(CHANGE_MUSIC_PROFILE_OF_ZONES, {
    update: updateZonesCacheAfterMutation,
    onCompleted: () => {
      setSelectedZones([]);
      onClose();
    },
  });

  const [
    createMusicProfile,
    { loading: loadingCreateMusicProfile, error: errorCreateMusicProfile },
  ] = useMutation(CREATE_MUSIC_PROFILE, {
    update: handleUpdateMusicProfileCacheAfterCreate,
    onCompleted: (data) => {
      // add selected zones to newly created zone and select the new profile
      const normalizedEntries = selectedZones
        .filter((changedZone) => Boolean(changedZone?.id))
        .map((changedZone) => ({
          zoneId: changedZone.id,
          musicProfileId:
            data?.createMusicProfile?.id !== '-1'
              ? data.createMusicProfile.id
              : null,
        }));
      changeMusicProfileOfZones({ variables: { entries: normalizedEntries } });
      setProfileId(data?.createMusicProfile?.id);
    },
  });

  const handleGoBack = useCallback(() => {
    const currentIndex = steps.findIndex((currentStep) => currentStep === step);
    if (currentIndex < 1) {
      onClose();
    } else {
      setStep(steps[currentIndex - 1]);
    }
  }, [step, onClose]);

  const handleGoNext = useCallback(() => {
    const currentIndex = steps.findIndex((currentStep) => currentStep === step);
    if (step === steps[steps.length - 1]) {
      createMusicProfile({
        variables: {
          name,
          musicRegionId: regionId,
          musicLicenseId: categoryId,
        },
      });
    } else {
      setStep(steps[currentIndex + 1]);
    }
  }, [step, createMusicProfile, name, regionId, categoryId]);

  const regions = useMemo(
    () => regionsData?.musicRegions ?? [],
    [regionsData?.musicRegions]
  );

  const zones = useMemo(() => zonesData?.zones ?? [], [zonesData?.zones]);

  return (
    <Modal
      footer={false}
      isOpen={isOpen}
      onClose={onClose}
      title={t('musicManagement:addMusicProfile.title')}
    >
      {step === steps[0] ? (
        <CategoryStep
          error={errorProducts}
          musicLicenses={musicLicenses}
          musicLicensesLoading={loadingProducts}
          next={handleGoNext}
          setCategoryId={setCategoryId}
        />
      ) : null}
      {step === steps[1] ? (
        <DetailsStep
          error={errorRegions}
          goBack={handleGoBack}
          loadingRegions={loadingRegions}
          name={name}
          onNext={handleGoNext}
          region={regionId}
          regions={regions}
          setName={setName}
          setRegionId={setRegionId}
        />
      ) : null}
      {step === steps[2] ? (
        <ZonesStep
          error={
            zonesError ||
            errorCreateMusicProfile ||
            errorChangeMusicProfileOfZones
          }
          goBack={handleGoBack}
          loading={loadingZones}
          loadingCreateMusicProfile={
            loadingCreateMusicProfile || loadingChangeMusicProfileOfZones
          }
          name={name}
          next={handleGoNext}
          selectedZones={selectedZones}
          setSelectedZones={setSelectedZones}
          zones={zones}
        />
      ) : null}
    </Modal>
  );
};

export default AddMusicProfileModal;
