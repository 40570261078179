import { useMutation, useQuery } from '@apollo/react-hooks';
import qs from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useQuery as useFetch,
  useInfiniteQuery as useInfiniteFetch,
} from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  ErrorMessage,
  LoadingIndicator,
  Modal,
  ZoneSelectionModal,
  useLocalStorage,
} from '../../../common';
import config from '../../../config';
import {
  GET_PLAYLIST_STATUSES,
  GET_ZONES_APPLICABLE_FOR_SPOTIFY_PLAYLIST_IMPORT,
  IMPORT_SPOTIFY_PLAYLISTS,
} from './api';

import { useAuth } from '../../../global/auth/newAuthProvider';
import { SpotifyImportContext } from './spotifyImportContext';
import {
  fetchSpotifyPlaylistsCallback,
  fetchSpotifyUserCallback,
  fetchTracksForPlaylists,
} from './utils';

const { SPOTIFY_CLIENT_ID, SPOTIFY_LOGIN_REDIRECT_URL, SPOTIFY_BASE_URL } =
  config;

const LIMIT = 20;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 2rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;

const Wrapper = styled.div`
  display: grid;
`;

const SpotifyImportProvider = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [playlistStatuses, setPlaylistStatuses] = useState();

  const [zoneSelectionModalIsOpen, setZoneSelectionModalIsOpen] =
    useState(false);
  const toggleZoneSelectionModal = useCallback(
    () => setZoneSelectionModalIsOpen((prev) => !prev),
    []
  );

  const [importIsLoading, setImportIsLoading] = useState(false);

  const { user } = useAuth();

  const [spotifyAccessToken, setSpotifyAccessToken, removeSpotifyAccessToken] =
    useLocalStorage(`${user.id}_spotify_access_token`);

  const [
    spotifyAccessTokenExpiresAt,
    setSpotifyAccessTokenExpiresAt,
    removeSpotifyAccessTokenExpiresAt,
  ] = useLocalStorage(`${user.id}_spotify_access_token_expires_at`);

  const parseHash = useCallback(
    (hash) => {
      const queryParameters = qs.parse(hash);
      if (queryParameters.access_token) {
        setSpotifyAccessToken(queryParameters.access_token);
        setSpotifyAccessTokenExpiresAt(
          new Date().getTime() + queryParameters.expires_in * 1000
        );
        setIsAuthenticated(true);
      }
    },
    [setSpotifyAccessToken, setSpotifyAccessTokenExpiresAt]
  );

  useEffect(() => {
    if (spotifyAccessToken && spotifyAccessTokenExpiresAt) {
      // Add 60 seconds to the current timestamp to mitigate sync problems between the app and the API.
      const cutOffTimeStamp = new Date().getTime() + 60000;
      const accessTokenIsExpired =
        cutOffTimeStamp >= spotifyAccessTokenExpiresAt;

      if (accessTokenIsExpired) {
        removeSpotifyAccessToken();
        removeSpotifyAccessTokenExpiresAt();
      } else {
        setIsAuthenticated(true);
      }
    }
    if (location.hash) {
      parseHash(window.location.hash);
      window.location.hash = '';
    }
  }, [
    removeSpotifyAccessToken,
    removeSpotifyAccessTokenExpiresAt,
    spotifyAccessToken,
    spotifyAccessTokenExpiresAt,
    location.hash,
    parseHash,
  ]);

  const handleLogout = useCallback(() => {
    removeSpotifyAccessToken();
    removeSpotifyAccessTokenExpiresAt();
    setIsAuthenticated(false);
  }, [removeSpotifyAccessTokenExpiresAt, removeSpotifyAccessToken]);

  const spotifyUserCallback = fetchSpotifyUserCallback(
    SPOTIFY_BASE_URL,
    spotifyAccessToken
  );

  const {
    isLoading: spotifyUserLoading,
    error: spotifyUserError,
    data: spotifyUserData,
    fetchStatus,
  } = useFetch(['spotifyUser'], spotifyUserCallback, {
    enabled: !!isAuthenticated,
    onError: (err) => {
      if (err.status === 401) {
        handleLogout();
      }
    },
  });

  const spotifyPlaylistsCallback = fetchSpotifyPlaylistsCallback(
    SPOTIFY_BASE_URL,
    spotifyAccessToken,
    LIMIT
  );

  const {
    isLoading: spotifyPlaylistsLoading,
    error: spotifyPlaylistsError,
    data: spotifyPlaylistsData,
    isFetchingNextPage: spotifyPlaylistsFetchingMore,
    fetchNextPage: spotifyPlaylistsFetchMore,
    hasNextPage: spotifyPlaylistsHasMore,
  } = useInfiniteFetch(['spotifyPlaylists'], spotifyPlaylistsCallback, {
    getNextPageParam: (lastGroup) =>
      lastGroup.nextCursor ? lastGroup.pageParam + LIMIT : null,
    enabled: !!(isAuthenticated && spotifyUserData?.display_name),
    onError: (err) => {
      if (err.status === 401) {
        handleLogout();
      }
    },
  });

  const {
    data: zonesData,
    loading: zonesLoading,
    error: zonesError,
  } = useQuery(GET_ZONES_APPLICABLE_FOR_SPOTIFY_PLAYLIST_IMPORT);

  const unsuccessfulPlaylistIds = useMemo(() => {
    if (!spotifyPlaylistsData) {
      return [];
    }

    if (!playlistStatuses) {
      // Fetch the statuses for all the playlists for the first time
      return spotifyPlaylistsData?.pages
        ?.flatMap((group) => group.items)
        .map((playlist) => playlist.id);
    }

    // Fetch extra statuses when items were added by scrolling
    const newSpotifyPlaylistsIds = spotifyPlaylistsData?.pages
      ?.flatMap((group) => group.items)
      .map((playlist) => playlist.id);
    if (playlistStatuses.length < newSpotifyPlaylistsIds.length) {
      return newSpotifyPlaylistsIds.filter(
        (playlistId) =>
          !playlistStatuses
            .map((playlist) => playlist.playlistId)
            .includes(playlistId)
      );
    }

    const unsuccessfulPlaylistStatuses = playlistStatuses.filter(
      (playlistStatus) =>
        playlistStatus.status === 'BUSY' ||
        playlistStatus.status === 'WAITING' ||
        playlistStatus.status === 'FAILED'
    );

    return unsuccessfulPlaylistStatuses.map(
      (playlistStatus) => playlistStatus.playlistId
    );
  }, [spotifyPlaylistsData, playlistStatuses]);

  const {
    data: playlistStatusesData,
    error: playlistStatusesError,
    loading: playlistStatusesLoading,
  } = useQuery(GET_PLAYLIST_STATUSES, {
    variables: {
      playlistIds: unsuccessfulPlaylistIds,
    },
    skip: unsuccessfulPlaylistIds.length === 0,
    pollInterval: unsuccessfulPlaylistIds.length === 0 ? 0 : 10000, // Refetch playlist statuses every 10 seconds,
    notifyOnNetworkStatusChange: true, // This is required in order for `onCompleted` to trigger upon receiving a response for a polling request.
    onCompleted: (data) => {
      if (data) {
        const newPlaylistsStatuses = data.spotifyPlaylistStatuses;
        if (playlistStatuses) {
          // When we have already checked for the playlist statuses,
          // alter the statuses, don't just replace them.
          const newPlaylistIds = newPlaylistsStatuses.map(
            (playlist) => playlist.playlistId
          );
          const oldPlaylistStatuses = playlistStatuses.filter(
            (playlistStatus) =>
              !newPlaylistIds.includes(playlistStatus.playlistId)
          );
          setPlaylistStatuses([
            ...oldPlaylistStatuses,
            ...newPlaylistsStatuses,
          ]);
        } else {
          setPlaylistStatuses(newPlaylistsStatuses);
        }
      }
    },
  });

  const handleLogin = useCallback(() => {
    window.location = `https://accounts.spotify.com/authorize?client_id=${SPOTIFY_CLIENT_ID}&redirect_uri=${SPOTIFY_LOGIN_REDIRECT_URL}&scope=playlist-read-private%20playlist-read-collaborative&response_type=token&show_dialog=true`;
  }, []);

  const togglePlaylistCheckBox = useCallback(
    (toggledPlaylist) => {
      if (
        selectedPlaylists
          .map((selectedPlaylist) => selectedPlaylist.id)
          .includes(toggledPlaylist.id)
      ) {
        setSelectedPlaylists((prev) =>
          prev.filter(
            (selectedPlaylist) => selectedPlaylist.id !== toggledPlaylist.id
          )
        );
      } else {
        setSelectedPlaylists((prev) => [...prev, toggledPlaylist]);
      }
    },
    [selectedPlaylists]
  );

  const toggleAllPlaylistCheckBoxes = useCallback(() => {
    const concatenatedSpotifyPlaylists =
      spotifyPlaylistsData?.pages?.flatMap((group) => group.items) || [];

    // Object mapping for fast lookups
    const playlistStatusesByIds = (
      playlistStatusesData?.spotifyPlaylistStatuses || []
    ).reduce((acc, curr) => {
      acc[curr.playlistId] = curr.status;
      return acc;
    }, {});

    // We do not take the disabled playlist rows in account
    const filteredPlaylists = concatenatedSpotifyPlaylists.filter(
      (playlist) =>
        playlistStatusesByIds[playlist.id] !== 'WAITING' &&
        playlistStatusesByIds[playlist.id] !== 'BUSY'
    );

    if (selectedPlaylists.length === filteredPlaylists.length) {
      setSelectedPlaylists([]);
    } else {
      setSelectedPlaylists(filteredPlaylists);
    }
    return null;
  }, [selectedPlaylists, spotifyPlaylistsData, playlistStatusesData]);

  const uncheckPlaylistCheckBoxes = useCallback((playlistIds) => {
    const playlistMap = playlistIds.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

    setSelectedPlaylists((prev) =>
      prev.filter((playlist) => !playlistMap[playlist.id])
    );
  }, []);

  const [importSpotifyPlaylists, { error: importSpotifyPlaylistsError }] =
    useMutation(IMPORT_SPOTIFY_PLAYLISTS, {
      onCompleted: (data) => {
        setImportIsLoading(false);
        // Make sure the user cannot import the playlist again if the import succeeds and the status is BUSY or WAITING
        uncheckPlaylistCheckBoxes(
          selectedPlaylists.map((selectedPlaylist) => selectedPlaylist.id)
        );
        toggleZoneSelectionModal();
        const importedPlaylistsStatusses = data.importSpotifyPlaylists;
        const importedPlaylistIds = importedPlaylistsStatusses.map(
          (playlist) => playlist.playlistId
        );
        const oldPlaylistStatuses = playlistStatuses.filter(
          (playlistStatus) =>
            !importedPlaylistIds.includes(playlistStatus.playlistId)
        );
        setPlaylistStatuses([
          ...oldPlaylistStatuses,
          ...importedPlaylistsStatusses,
        ]);
      },
      onError: () => {
        setImportIsLoading(false);
      },
      refetchQueries: ['getPlaylistStatuses'],
      awaitRefetchQueries: true,
    });

  const [spotifyRequestError, setSpotifyRequestError] = useState();

  const handleClose = useCallback(() => {
    if (!importIsLoading) {
      // Remove error message when modal is closed
      setSpotifyRequestError(null);
      toggleZoneSelectionModal();
    }
  }, [importIsLoading, toggleZoneSelectionModal]);

  const importPlaylists = useCallback(
    async (zones) => {
      setImportIsLoading(true);
      const zoneIds = zones.map((zone) => Number(zone.id));
      try {
        const playlistsWithTracks = await fetchTracksForPlaylists(
          selectedPlaylists,
          spotifyAccessToken
        );
        importSpotifyPlaylists({
          variables: { playlists: playlistsWithTracks, zoneIds },
        });
      } catch (err) {
        setImportIsLoading(false);
        if (err.status === 401) {
          setSpotifyRequestError({ name: 'SPOTIFY_IMPORT_UNAUTHORIZED' });
        } else if (err.status === 429) {
          setSpotifyRequestError({ name: 'SPOTIFY_IMPORT_TOO_MANY_REQUESTS' });
        }
      }
    },
    [
      selectedPlaylists,
      importSpotifyPlaylists,
      spotifyAccessToken,
      setImportIsLoading,
    ]
  );

  const contextValue = useMemo(
    () => ({
      logout: handleLogout,
      isAuthenticated,
      userName: spotifyUserData?.display_name,
      playlists:
        spotifyPlaylistsData?.pages?.flatMap((group) => group.items) || [],
      playlistsLoading: spotifyPlaylistsLoading,
      playlistsError: spotifyPlaylistsError,
      playlistsLoadingMore: spotifyPlaylistsFetchingMore,
      playlistStatusesError,
      playlistStatuses: playlistStatuses || [],
      playlistStatusesLoading,
      loadMorePlaylists: spotifyPlaylistsFetchMore,
      hasMorePlaylists: spotifyPlaylistsHasMore,
      selectedPlaylists,
      togglePlaylistCheckBox,
      toggleAllPlaylistCheckBoxes,
      zones: zonesData?.zonesApplicableForSpotifyPlaylistImport || [],
      zonesLoading,
      zonesError,
      toggleZoneSelectionModal,
      uncheckPlaylistCheckBoxes,
    }),
    [
      isAuthenticated,
      handleLogout,
      spotifyPlaylistsFetchMore,
      spotifyUserData,
      spotifyPlaylistsData,
      spotifyPlaylistsLoading,
      spotifyPlaylistsError,
      spotifyPlaylistsHasMore,
      playlistStatusesError,
      playlistStatusesLoading,
      selectedPlaylists,
      togglePlaylistCheckBox,
      toggleAllPlaylistCheckBoxes,
      spotifyPlaylistsFetchingMore,
      zonesData,
      zonesLoading,
      zonesError,
      toggleZoneSelectionModal,
      uncheckPlaylistCheckBoxes,
      playlistStatuses,
    ]
  );

  const onCloseModal = useCallback(() => {
    navigate('/playlists');
  }, [navigate]);

  const onConfirmModal = useCallback(() => {
    if (!isAuthenticated) {
      handleLogin();
    } else {
      toggleZoneSelectionModal();
    }
  }, [isAuthenticated, handleLogin, toggleZoneSelectionModal]);

  const confirmText = useMemo(() => {
    if (isAuthenticated) {
      return t('common:button.next');
    } else {
      return t('common:button.login');
    }
  }, [isAuthenticated, t]);

  const cancelText = useMemo(() => {
    return t('common:button.cancel');
  }, [t]);

  if (spotifyUserLoading && fetchStatus !== 'idle')
    return <StyledLoadingIndicator />;

  if (spotifyUserError) return <StyledErrorMessage error={spotifyUserError} />;

  return (
    <SpotifyImportContext.Provider value={contextValue}>
      <Modal
        isOpen
        shouldCloseOnOverlayClick={false}
        onClose={onCloseModal}
        title={t('integrations:spotify.import.title')}
        confirmText={confirmText}
        cancelText={cancelText}
        confirmHighlightGradient="nightfall"
        confirmDisabled={isAuthenticated && selectedPlaylists.length === 0}
        onConfirm={onConfirmModal}
      >
        <Wrapper>{children}</Wrapper>
      </Modal>

      <ZoneSelectionModal
        actionIsLoading={importIsLoading}
        error={importSpotifyPlaylistsError || spotifyRequestError || zonesError}
        instructions={t(
          'integrations:spotify.import.zoneSelectionModalDescription'
        )}
        isMulti
        isOpen={zoneSelectionModalIsOpen}
        onClose={handleClose}
        onConfirm={importPlaylists}
        title={t('integrations:spotify.import.zoneSelectionModalTitle')}
        zones={zonesData?.zonesApplicableForSpotifyPlaylistImport?.map(
          (zone) => ({
            ...zone,
            organizationName: zone.location.organization.name,
            locationName: zone.location.name,
          })
        )}
      />
    </SpotifyImportContext.Provider>
  );
};

export default SpotifyImportProvider;
