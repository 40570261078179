import gql from 'graphql-tag';

const GET_AVAILABLE_PRODUCTS_FOR_ORGANIZATION_AND_SUBSCRIPTION_LINES = gql`
  query getAvailableProductsForOrganizationAndSubscriptionLines($organizationId: ID!) {
    organization(id: $organizationId) {
      id
      name
      city
      zip
      streetName
      streetNumber
      vat
      countryId
      zonesWithARenewableSubscription {
        id
        name
        location {
          id
          name
        }
        lastSubscription {
          id
          endDate
          subscriptionLines {
            id
            product {
              id
              isForever
              isAddon
              shortName
              musicLicense {
                id
                name
              }
              color {
                id
                name
              }
              subscriptionTemplate {
                id
                recurringInterval
              }
            }
          }
        }
      }
      availablePaidProducts {
        id
        isForever
        price
        color {
          id
        }
        musicLicense {
          id
        }
        subscriptionTemplate {
          id
          recurringInterval
        }
      }
    }
    availablePaidAddons(organizationId: $organizationId) {
      id
      shortName
      saleOk
      isForever
      price
      subscriptionTemplate {
        id
        recurringInterval
      }
    }
  }
`;

const GET_PAYMENT_OPTIONS = gql`
  query getPaymentOptions($productIds: [ID!]!, $countryId: ID!) {
    paymentOptions(productIds: $productIds, countryId: $countryId) {
      id
      name
      image
      pm
      brand
      paymentAcquirerId
      isWireTransfer
    }
  }
`;

const ADD_ITEMS_TO_CART = gql`
  mutation addItemsToCart($organizationId: ID!, $cartItems: [CartItemInput!]!, $cartId: ID) {
    addItemsToCart(organizationId: $organizationId, cartItems: $cartItems, cartId: $cartId) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
        zone {
          id
        }
        product {
          id
          subscriptionTemplate {
            id
            recurringInterval
          }
        }
      }
    }
  }
`;

const REMOVE_ITEMS_FROM_CART = gql`
  mutation removeItemsFromCart($organizationId: ID!, $cartItemIds: [String!]!, $cartId: ID!) {
    removeItemsFromCart(organizationId: $organizationId, cartItemIds: $cartItemIds, cartId: $cartId) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
        zone {
          id
        }
        product {
          id
          subscriptionTemplate {
            id
            recurringInterval
          }
        }
      }
    }
  }
`;

const SWITCH_CART_ITEMS = gql`
  mutation switchCartItems(
    $organizationId: ID!
    $cartItemIds: [String!]!
    $cartItems: [CartItemInput!]!
    $cartId: ID!
  ) {
    removeItemsFromCart(organizationId: $organizationId, cartItemIds: $cartItemIds, cartId: $cartId) {
      id
    }
    addItemsToCart(organizationId: $organizationId, cartItems: $cartItems, cartId: $cartId) {
      id
      amountUntaxed
      amountTax
      amountTotal
      saleOrderLines {
        priceUnit
        cartLineId
        zone {
          id
        }
        product {
          id
          subscriptionTemplate {
            id
            recurringInterval
          }
        }
      }
    }
  }
`;

const PREPARE_PAYMENT = gql`
  mutation preparePayment(
    $ingenicoParams: NewPaymentInput!
    $saleOrderId: Int!
    $paymentAcquirerId: Int!
    $paymentIconId: Int!
  ) {
    preparePayment(
      ingenicoParams: $ingenicoParams
      saleOrderId: $saleOrderId
      paymentAcquirerId: $paymentAcquirerId
      paymentIconId: $paymentIconId
    ) {
      id
      reference
      amount
      currency
      wireTransferReference
      iban
      bic
      ingenicoShaIn
      ogonePspid
      ogoneAlias
      ogoneAliasUsage
      ownerCountryCode
    }
  }
`;

export {
  GET_AVAILABLE_PRODUCTS_FOR_ORGANIZATION_AND_SUBSCRIPTION_LINES,
  GET_PAYMENT_OPTIONS,
  SWITCH_CART_ITEMS,
  PREPARE_PAYMENT,
  ADD_ITEMS_TO_CART,
  REMOVE_ITEMS_FROM_CART,
};
