import { useMemo, useCallback, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useParams } from 'react-router-dom';

import { renewPurchaseReducer, initialRenewPurchaseState, RenewPurchaseAction } from './renewPurchaseReducer';
import { LoadingIndicator, ErrorMessage } from '../../../common';
import { useRenewPurchase, RenewPurchaseContext } from './renewPurchaseContext';
import {
  GET_AVAILABLE_PRODUCTS_FOR_ORGANIZATION_AND_SUBSCRIPTION_LINES,
  ADD_ITEMS_TO_CART,
  REMOVE_ITEMS_FROM_CART,
  SWITCH_CART_ITEMS,
  GET_PAYMENT_OPTIONS,
} from './api';

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin-top: 3rem;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 3rem;
`;

const RenewPurchaseProvider = ({ children }) => {
  const { organizationId } = useParams();

  const [state, dispatch] = useReducer(renewPurchaseReducer, initialRenewPurchaseState);

  useEffect(() => {
    // Whenever we mount, we reset the state values to the initial state
    // This is necessary because when we switch organizations (with the notifications drawer)
    // the state of the previous renew-subscriptions page will still be intact because this
    // provider does not unmount.
    dispatch({
      type: RenewPurchaseAction.CLEAR_STATE,
    });
  }, []);

  const { loading: initialQueryLoading, error: initialQueryError } = useQuery(
    GET_AVAILABLE_PRODUCTS_FOR_ORGANIZATION_AND_SUBSCRIPTION_LINES,
    {
      variables: { organizationId },
      // This is necessary because otherwise Apollo will "remember" the previous results
      // And will execute the switchCurrentProductsToForeverEquivalents twice, resulting in
      // duplicate lines in the sale order.
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        dispatch({
          type: RenewPurchaseAction.FETCHED_INITIAL_QUERY,
          payload: { initialQuery: data },
        });
      },
    }
  );

  const [addItemsToCart, { loading: addItemsToCartLoading, error: addItemsToCartError }] = useMutation(
    ADD_ITEMS_TO_CART,
    {
      onCompleted: (data) => {
        dispatch({
          type: RenewPurchaseAction.ADDED_ITEMS_TO_CART,
          payload: { saleOrder: data.addItemsToCart },
        });
      },
    }
  );

  const [removeItemsFromCart, { loading: removeItemsFromCartLoading, error: removeItemsFromCartError }] = useMutation(
    REMOVE_ITEMS_FROM_CART,
    {
      onCompleted: (data) => {
        dispatch({
          type: RenewPurchaseAction.REMOVED_ITEMS_FROM_CART,
          payload: { saleOrder: data.removeItemsFromCart },
        });
      },
    }
  );

  const [switchCartItems, { loading: switchCartItemsLoading, error: switchCartItemsError }] = useMutation(
    SWITCH_CART_ITEMS,
    {
      onCompleted: (data) => {
        dispatch({
          type: RenewPurchaseAction.SWITCHED_CART_ITEMS,
          payload: { saleOrder: data.addItemsToCart },
        });
      },
    }
  );

  const { loading: paymentOptionsLoading, error: paymentOptionsError } = useQuery(GET_PAYMENT_OPTIONS, {
    skip: !state.saleOrderProductIds.length,
    variables: {
      countryId: state.organization?.countryId,
      productIds: state.saleOrderProductIds,
    },
    onCompleted: (data) => {
      if (data) {
        dispatch({
          type: RenewPurchaseAction.FETCHED_PAYMENT_OPTIONS,
          payload: { paymentOptions: data.paymentOptions },
        });
      }
    },
  });

  useEffect(() => {
    if (state.cartItemsToAdd.length > 0) {
      addItemsToCart({
        variables: {
          organizationId: state.organization.id,
          cartItems: state.cartItemsToAdd,
          cartId: state.saleOrder ? state.saleOrder.id : null,
        },
      });
    }
    if (state.cartItemIdsToRemove.length > 0) {
      removeItemsFromCart({
        variables: {
          organizationId: state.organization.id,
          cartItemIds: state.cartItemIdsToRemove,
          cartId: state.saleOrder.id,
        },
      });
    }
    if (state.cartItemsToSwitch.cartItemIdsToRemove.length > 0) {
      switchCartItems({
        variables: {
          organizationId: state.organization.id,
          cartItemIds: state.cartItemsToSwitch.cartItemIdsToRemove,
          cartItems: state.cartItemsToSwitch.cartItemsToAdd,
          cartId: state.saleOrder.id,
        },
      });
    }
  }, [
    state.cartItemsToAdd,
    state.cartItemsToSwitch,
    state.cartItemIdsToRemove,
    state.organization,
    state.saleOrder,
    removeItemsFromCart,
    addItemsToCart,
    switchCartItems,
  ]);

  const switchIsForever = useCallback((wantsForever) => {
    dispatch({
      type: RenewPurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH,
      payload: { wantsForever },
    });
  }, []);

  const toggleSelectedZone = useCallback(
    (zone) => {
      if (state.userInput.selectedZones.some((selectedZone) => selectedZone.id === zone.id)) {
        dispatch({
          type: RenewPurchaseAction.DESELECT_ZONE,
          payload: { zone },
        });
      } else {
        dispatch({
          type: RenewPurchaseAction.SELECT_ZONE,
          payload: { zone },
        });
      }
    },
    [state]
  );

  const selectPaymentOption = useCallback((paymentOption) => {
    dispatch({ type: RenewPurchaseAction.SELECT_PAYMENT_OPTION, payload: { paymentOption } });
  }, []);

  const content = useMemo(() => {
    if (initialQueryLoading) return <StyledLoadingIndicator />;
    if (initialQueryError) return <StyledErrorMessage withPadding error={initialQueryError} />;
    return children;
  }, [children, initialQueryLoading, initialQueryError]);

  const renewPurchaseValue = useMemo(
    () => ({
      organization: state.organization,
      isForever: state.userInput.wantsForever,
      switchIsForever,
      selectedZones: state.userInput.selectedZones,
      toggleSelectedZone,
      cartLoading: addItemsToCartLoading || removeItemsFromCartLoading || switchCartItemsLoading,
      cartError: addItemsToCartError || removeItemsFromCartError || switchCartItemsError,
      order: state.saleOrder,
      selectPaymentOption,
      selectedPaymentOption: state.userInput.selectedPaymentOption,
      availableProducts: state.availableProducts,
      availablePaymentOptions: state.availablePaymentOptions,
      availableAddons: state.availablePaidAddons,
      zones: state.zones,
      paymentOptionsError,
      paymentOptionsLoading,
    }),
    [
      state,
      addItemsToCartLoading,
      addItemsToCartError,
      removeItemsFromCartLoading,
      removeItemsFromCartError,
      switchCartItemsLoading,
      switchCartItemsError,
      toggleSelectedZone,
      switchIsForever,
      selectPaymentOption,
      paymentOptionsError,
      paymentOptionsLoading,
    ]
  );

  return <RenewPurchaseContext.Provider value={renewPurchaseValue}>{content}</RenewPurchaseContext.Provider>;
};

export { RenewPurchaseProvider, useRenewPurchase };
