import { useMutation } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  ErrorMessage,
  GoBackButton,
  HiddenIngenicoForm,
  InfoCard,
  Page,
} from '../../../common';
import { PREPARE_PAYMENT } from './api';
import OrderTable from './orderTable';
import OrganizationDetails from './organizationDetails';
import PaymentOptions from './paymentOptions';
import { useRenewPurchase } from './renewPurchaseContext';
import { RenewPurchaseProvider } from './renewPurchaseProvider';

import { TermsAndConditions } from '../../../common/termsAndConditions';
import config from '../../../config';
import { useAuth } from '../../../global/auth/newAuthProvider';

const { BASE_URL } = config;

const StyledPage = styled(Page)`
  height: calc(100vh - 7rem);
  @supports (height: 100svh) {
    height: calc(100svh - 7rem);
  }
  display: grid;
  grid-auto-rows: min-content 1fr min-content;
  overflow-y: auto;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    height: 100vh;
    @supports (height: 100svh) {
      height: 100svh;
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-template-rows: 1fr 8rem;
    grid-template-columns: 1fr 2fr;
  }
`;

const Left = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  justify-items: start;
  gap: 2rem;
  background-color: ${({ theme }) => theme.white};
  padding: 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 1 / 1 / span 2 / 1;
    overflow-y: auto;
    padding: 6.4rem 3.2rem 10rem 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 6.4rem 4.8rem 10rem 4.8rem;
  }
`;

const Right = styled.div`
  display: grid;
  grid-auto-rows: min-content;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 1.6rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 1 / 2 / 1 / 2;
    padding: 7.2rem 3.2rem;
    overflow-y: auto;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 7.2rem;
  }
`;

const Container = styled.div`
  display: grid;
  gap: 2rem;
`;

const Bottom = styled.div`
  min-height: 7rem;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ theme }) => theme.dark500};
  z-index: 1;
  padding: 0 1.6rem;
  position: sticky;
  bottom: 0;
  left: 0;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    grid-area: 2 / 2 / 2 / 2;
    padding: 0 3.2rem;
  }
`;

const StyledButton = styled(Button)`
  & p {
    color: ${({ theme }) => theme.white};
  }
  &:hover {
    border-color: ${({ theme }) => theme.dark200};
  }
`;

const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.4;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    font-size: 2.8rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.4;
  color: ${({ theme }) => theme.dark200};
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: 1.6rem;
`;

const StyledTermsAndConditions = styled(TermsAndConditions)`
  margin-top: 2rem;
`;

const RenewPurchase = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { organizationId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const formRef = useRef(null);
  const {
    selectedPaymentOption,
    order,
    organization,
    cartError,
    cartLoading,
    zones,
    selectedZones,
  } = useRenewPurchase();

  const [istermsAndConditionsChecked, setIsTermsAndConditionsChecked] =
    useState(false);

  const [
    preparePayment,
    { loading: createPaymentLoading, error: createPaymentError },
  ] = useMutation(PREPARE_PAYMENT, {
    onCompleted: ({ preparePayment: preparePaymentResult }) => {
      if (
        preparePaymentResult.wireTransferReference &&
        preparePaymentResult.iban &&
        preparePaymentResult.bic
      ) {
        // / User selected wire transfer and will not be redirected to Ingenico
        const { amount, wireTransferReference, iban } = preparePaymentResult;
        navigate(
          `/payment-feedback/wire-transfer`,
          {
            state: {
              amount,
              iban,
              wireTransferReference,
            },
          }
        );
      } else {
        formRef.current.PSPID.value = preparePaymentResult.ogonePspid;
        formRef.current.ORDERID.value = preparePaymentResult.reference;
        formRef.current.AMOUNT.value = Number(preparePaymentResult.amount);
        formRef.current.CURRENCY.value = preparePaymentResult.currency;
        formRef.current.SHASIGN.value = preparePaymentResult.ingenicoShaIn;
        formRef.current.ALIAS.value = preparePaymentResult.ogoneAlias;
        formRef.current.ALIASUSAGE.value = preparePaymentResult.ogoneAliasUsage;
        formRef.current.OWNERCTY.value = preparePaymentResult.ownerCountryCode;
        formRef.current.submit();
      }
    },
  });

  const handlePay = useCallback(() => {
    const inputs = formRef.current.querySelectorAll('input');
    const newPaymentValues = {};
    inputs.forEach((input) => {
      if (
        input.name !== 'PSPID' &&
        input.name !== 'ORDERID' &&
        input.name !== 'AMOUNT' &&
        input.name !== 'CURRENCY' &&
        input.name !== 'SHASIGN' &&
        input.name !== 'ALIAS' &&
        input.name !== 'ALIASUSAGE'
      ) {
        newPaymentValues[input.name] = input.value;
      }
    });

    preparePayment({
      variables: {
        ingenicoParams: newPaymentValues,
        saleOrderId: Number(order.id),
        paymentAcquirerId: Number(selectedPaymentOption.paymentAcquirerId),
        paymentIconId: Number(selectedPaymentOption.id),
      },
    });
  }, [preparePayment, order, selectedPaymentOption]);

  const isPayDisabled = useMemo(
    () =>
      !istermsAndConditionsChecked ||
      !selectedPaymentOption ||
      cartLoading ||
      selectedZones.length < 1,
    [
      istermsAndConditionsChecked,
      selectedPaymentOption,
      cartLoading,
      selectedZones.length,
    ]
  );

  const handleOnCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    let paramContent = { zoneIds: selectedZones.map((selectedZone) => selectedZone.id).join(',') };
    if (order?.id) {
      paramContent = { ...paramContent, saleOrderId: order.id };
    }
    setSearchParams(paramContent);
  }, [order?.id, selectedZones, setSearchParams]);

  const paramPlus = useMemo(() => searchParams.toString(), [searchParams]);

  const handleTermsAndConditionsChange = useCallback((event) => {
    setIsTermsAndConditionsChecked(event.target.checked);
  }, []);

  return (
    <StyledPage title={t('purchase:renewSubscriptions.title')}>
      <Left>
        <GoBackButton
          to="/subscriptions"
          label={t('common:button.cancel')}
          title={t('common:button.title.cancel')}
        />
        <Title>{t('purchase:renewSubscriptions.title')}</Title>
        <OrganizationDetails />
      </Left>
      <Right>
        <InfoCard title={t('subscriptions:buyLastStepTitle')}>
          {zones.length > 0 ? (
            <>
              <Container>
                <OrderTable />
                <PaymentOptions />
                {(cartError || createPaymentError) && (
                  <StyledErrorMessage error={cartError || createPaymentError} />
                )}
              </Container>
              {order && organization && (
                <HiddenIngenicoForm
                  ref={formRef}
                  brand={
                    (selectedPaymentOption && selectedPaymentOption.brand) || ''
                  }
                  cn={`${user.firstName} ${user.lastName}`}
                  email={user.emailAddress}
                  language={user.lang}
                  orderId={order.id}
                  ownerAddress={`${organization.streetName} ${organization.streetNumber}`}
                  ownerCty={organization.countryId}
                  ownerTown={organization.city}
                  ownerZip={organization.zip}
                  paramPlus={paramPlus}
                  pm={selectedPaymentOption?.pm || ''}
                  redirectUrlOnAccept={`${BASE_URL}/payment-feedback/accept`}
                  redirectUrlOnCancel={`${BASE_URL}/payment-feedback/cancel`}
                  redirectUrlOnDecline={`${BASE_URL}/payment-feedback/decline`}
                  redirectUrlOnException={`${BASE_URL}/payment-feedback/exception`}
                />
              )}
            </>
          ) : (
            <Description>
              {t('subscriptions:noRenewableSubscriptions')}
            </Description>
          )}
          <StyledTermsAndConditions onChange={handleTermsAndConditionsChange} />
        </InfoCard>
      </Right>
      <Bottom>
        <StyledButton
          emphasis="outline"
          highlightColor="dark400"
          onClick={handleOnCancel}
        >
          {t('common:button.cancel')}
        </StyledButton>
        <StyledButton
          emphasis="primary"
          highlightGradient="blooming"
          isDisabled={isPayDisabled || cartLoading}
          isLoading={createPaymentLoading}
          onClick={handlePay}
        >
          {t('common:button.pay')}
        </StyledButton>
      </Bottom>
    </StyledPage>
  );
};

const RenewPurchasePage = () => (
  <RenewPurchaseProvider>
    <RenewPurchase />
  </RenewPurchaseProvider>
);

export default RenewPurchasePage;
