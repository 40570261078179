import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PartnersPage from './partners';
import CommissionRequestsPage from './commissionRequests';
import PageHeader from './pageHeader';
import { Page } from '../../common';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    height: 100vh;
    @supports (height: 100svh) {
      height: 100svh;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-content: start;
  overflow-y: auto;
  padding: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3rem 3rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3rem 4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 3rem 4.8rem;
  }
`;

const OverviewPage = () => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState('partners');

  return (
    <Page title={t('partnerManager:partners.newTitle')}>
      <Container>
        <PageHeader
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <Grid>
          {selectedCategory === 'partners' ? <PartnersPage /> : null}
          {selectedCategory === 'commissionRequests' ? (
            <CommissionRequestsPage />
          ) : null}
        </Grid>
      </Container>
    </Page>
  );
};

export default OverviewPage;
