import { determineAvailableMusicLicenses } from '../../../common';
import {
  determineAvailableColors,
  determineAvailableSubscriptionTemplates,
  determineSelectedProduct,
  switchFromForeverToFixedProductAndViceVersa,
} from './utils';

export const productCatalogueOptions = [
  {
    name: 'Paid',
    id: 'paid',
    description: 'De reguliere, betaalde producten van Tunify',
  },
  { name: 'Demo', id: 'demo', description: 'De demo producten van Tunify' },
  { name: 'Free', id: 'free', description: 'De free producten van Tunify' },
  { name: 'Trial', id: 'trial', description: 'De trial producten van Tunify' },
];

export const PurchaseAction = {
  FETCHED_ORGANIZATION_DETAILS: 'FETCHED_ORGANIZATION_DETAILS',
  SELECT_PRODUCT_CATALOGUE: 'SELECT_PRODUCT_CATALOGUE',
  FETCHED_PRODUCT_CATALOGUE: 'FETCHED_PRODUCT_CATALOGUE',
  SELECT_MUSIC_LICENSE: 'SELECT_MUSIC_LICENSE',
  SELECT_COLOR: 'SELECT_COLOR',
  SELECT_SUBSCRIPTION_TEMPLATE: 'SELECT_SUBSCRIPTION_TEMPLATE',
  SELECT_ADDON: 'SELECT_ADDON',
  DESELECT_ADDON: 'DESELECT_ADDON',
  SELECT_ADDITIONAL_ZONE: 'SELECT_ADDITIONAL_ZONE',
  DESELECT_ADDITIONAL_ZONE: 'DESELECT_ADDITIONAL_ZONE',
  EDIT_ORGANIZATION_DETAILS: 'EDIT_ORGANIZATION_DETAILS',
  SELECT_PAYMENT_OPTION: 'SELECT_PAYMENT_OPTION',
  TOGGLE_FOREVER_PRODUCT_SWITCH: 'TOGGLE_FOREVER_PRODUCT_SWITCH',
  FETCHED_PAYMENT_OPTIONS: 'FETCHED_PAYMENT_OPTIONS',
  FETCHED_SALE_ORDER: 'FETCHED_SALE_ORDER',
  FETCHED_ADD_ONS: 'FETCHED_ADD_ONS',
};

export const initialPurchaseState = {
  userInput: {
    selectedMusicLicense: null,
    selectedColor: null,
    selectedSubscriptionTemplate: null,
    selectedAddons: [],
    selectedAdditionalZones: [],
    selectedPaymentOption: null,
    wantsForeverProduct: true,
  },
  saleOrderNeedsToBeFetched: true,
  saleOrder: null,
  selectedProduct: null,
  productCatalogue: [],
  organizationDetails: null,
  availableMusicLicenses: [],
  availableColors: [],
  availablePaymentOptions: [],
  availableSubscriptionTemplates: [],
  availableAdditionalZones: [],
  availableAddons: [],
  // Initially we select the paid product catalogue,
  // as is the case without the product catalogue step
  selectedProductCatalogue: productCatalogueOptions.find(
    (pc) => pc.id === 'paid'
  ),
};

export const purchaseReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case PurchaseAction.SELECT_PRODUCT_CATALOGUE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedMusicLicense: null,
          selectedColor: null,
          selectedSubscriptionTemplate: null,
          selectedPaymentOption: null,
          selectedAddons: [],
          // The product catalogues demo, free and trial have only fixed products,
          // so set the wantsForeverProduct userInput to false
          wantsForeverProduct: payload.productCatalogue.id === 'paid',
        },
        saleOrderNeedsToBeFetched: true,
        selectedProduct: null,
        availableColors: [],
        availableSubscriptionTemplates: [],
        availablePaymentOptions: [],
        selectedProductCatalogue: payload.productCatalogue,
      };

    case PurchaseAction.FETCHED_PRODUCT_CATALOGUE:
      return {
        ...state,
        productCatalogue: payload.productCatalogue,
        availableMusicLicenses: determineAvailableMusicLicenses(
          payload.productCatalogue,
          state.userInput.wantsForeverProduct
        ),
      };

    case PurchaseAction.FETCHED_ORGANIZATION_DETAILS:
      return {
        ...state,
        organizationDetails: payload.organizationDetails,
        availableAdditionalZones: payload.organizationDetails.zones.filter(
          ({ id }) => id !== payload.currentZoneId
        ),
      };

    case PurchaseAction.FETCHED_ADD_ONS:
      return {
        ...state,
        availableAddons: payload.availableAddons,
      };

    case PurchaseAction.SELECT_MUSIC_LICENSE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedMusicLicense: payload.musicLicense,
          selectedColor: null,
          selectedSubscriptionTemplate: null,
          selectedPaymentOption: null,
          selectedAddons: [],
        },
        saleOrderNeedsToBeFetched: true,
        selectedProduct: null,
        availableColors: determineAvailableColors(
          state.productCatalogue,
          payload.musicLicense.id,
          state.userInput.wantsForeverProduct
        ),
        availableSubscriptionTemplates: [],
        availablePaymentOptions: [],
      };

    case PurchaseAction.SELECT_COLOR:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedColor: payload.color,
          selectedSubscriptionTemplate: null,
          selectedPaymentOption: null,
          selectedAddons: [],
        },
        saleOrderNeedsToBeFetched: true,
        selectedProduct: null,
        availableSubscriptionTemplates: determineAvailableSubscriptionTemplates(
          state.productCatalogue,
          state.userInput.selectedMusicLicense.id,
          payload.color.id,
          state.userInput.wantsForeverProduct
        ),
        availablePaymentOptions: [],
      };

    case PurchaseAction.SELECT_SUBSCRIPTION_TEMPLATE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedSubscriptionTemplate: payload.subscriptionTemplate,
          selectedPaymentOption: null,
          selectedAddons: [],
        },
        saleOrderNeedsToBeFetched: true,
        selectedProduct: determineSelectedProduct(
          state.productCatalogue,
          state.userInput.selectedMusicLicense.id,
          state.userInput.selectedColor.id,
          payload.subscriptionTemplate.id
        ),
        availablePaymentOptions: [],
      };

    case PurchaseAction.SELECT_ADDON:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons: [...state.userInput.selectedAddons, payload.addon],
        },
        saleOrderNeedsToBeFetched: true,
      };

    case PurchaseAction.DESELECT_ADDON:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAddons: state.userInput.selectedAddons.filter(
            (selectedAddon) => selectedAddon.id !== payload.addon.id
          ),
        },
        saleOrderNeedsToBeFetched: true,
      };

    case PurchaseAction.SELECT_ADDITIONAL_ZONE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones:
            state.userInput.selectedAdditionalZones.concat(
              payload.additionalZone
            ),
        },
        saleOrderNeedsToBeFetched: true,
      };

    case PurchaseAction.DESELECT_ADDITIONAL_ZONE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedAdditionalZones:
            state.userInput.selectedAdditionalZones.filter(
              (zone) => zone.id !== payload.additionalZone.id
            ),
        },
        saleOrderNeedsToBeFetched: true,
      };

    case PurchaseAction.EDIT_ORGANIZATION_DETAILS:
      // Whenever the country or VAT has changed, create a new sale order
      // because the old sale order will still have the old organization details.
      const countryHasChanged =
        state.organizationDetails.countryId !==
        payload.organizationDetails.countryId;
      const vatHasChanged =
        state.organizationDetails.vat !== payload.organizationDetails.vat;

      return {
        ...state,
        organizationDetails: {
          ...state.organizationDetails,
          ...payload.organizationDetails,
        },
        saleOrderNeedsToBeFetched:
          countryHasChanged || vatHasChanged
            ? true
            : state.saleOrderNeedsToBeFetched,
        availablePaymentOptions:
          countryHasChanged || vatHasChanged
            ? []
            : state.availablePaymentOptions,
        saleOrder: countryHasChanged || vatHasChanged ? null : state.saleOrder,
      };

    case PurchaseAction.SELECT_PAYMENT_OPTION:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedPaymentOption: payload.paymentOption,
        },
      };

    case PurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH:
      const newProduct = switchFromForeverToFixedProductAndViceVersa(
        state.selectedProduct,
        state.productCatalogue
      );
      return {
        ...state,
        userInput: {
          ...state.userInput,
          wantsForeverProduct: payload.wantsForeverProduct,
          selectedSubscriptionTemplate: newProduct.subscriptionTemplate,
          selectedPaymentOption: null,
        },
        availablePaymentOptions: [],
        availableMusicLicenses: determineAvailableMusicLicenses(
          state.productCatalogue,
          payload.wantsForeverProduct
        ),
        availableColors: determineAvailableColors(
          state.productCatalogue,
          state.userInput.selectedMusicLicense.id,
          payload.wantsForeverProduct
        ),
        availableSubscriptionTemplates: determineAvailableSubscriptionTemplates(
          state.productCatalogue,
          state.userInput.selectedMusicLicense.id,
          state.userInput.selectedColor.id,
          payload.wantsForeverProduct
        ),
        saleOrderNeedsToBeFetched: true,
        selectedProduct: newProduct,
      };

    case PurchaseAction.FETCHED_PAYMENT_OPTIONS:
      return {
        ...state,
        availablePaymentOptions: payload.paymentOptions,
      };

    case PurchaseAction.FETCHED_SALE_ORDER:
      return {
        ...state,
        saleOrder: payload.saleOrder,
        saleOrderNeedsToBeFetched: false,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
