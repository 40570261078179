import { buildCartItems } from './utils';

export const RenewPurchaseAction = {
  FETCHED_INITIAL_QUERY: 'FETCHED_INITIAL_QUERY',
  SELECT_ZONE: 'SELECT_ZONE',
  ADDED_ITEMS_TO_CART: 'ADDED_ITEMS_TO_CART',
  DESELECT_ZONE: 'DESELECT_ZONE',
  REMOVED_ITEMS_FROM_CART: 'REMOVED_ITEMS_FROM_CART',
  TOGGLE_FOREVER_PRODUCT_SWITCH: 'TOGGLE_FOREVER_PRODUCT_SWITCH',
  SWITCHED_CART_ITEMS: 'SWITCHED_CART_ITEMS',
  FETCHED_PAYMENT_OPTIONS: 'FETCHED_PAYMENT_OPTIONS',
  SELECT_PAYMENT_OPTION: 'SELECT_PAYMENT_OPTION',
  CLEAR_STATE: 'CLEAR_STATE',
};

export const initialRenewPurchaseState = {
  userInput: { selectedZones: [], selectedPaymentOption: null, wantsForever: true },
  organization: null,
  zones: [],
  availableProducts: [],
  availablePaidAddons: [],
  availablePaymentOptions: [],
  saleOrder: null,
  saleOrderProductIds: [],
  cartItemsToAdd: [],
  cartItemIdsToRemove: [],
  cartItemsToSwitch: { cartItemsToAdd: [], cartItemIdsToRemove: [] },
};

export const renewPurchaseReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case RenewPurchaseAction.FETCHED_INITIAL_QUERY: {
      const {
        initialQuery: { organization, availablePaidAddons },
      } = payload;

      const cartItemsToAdd = buildCartItems(
        organization.zonesWithARenewableSubscription,
        organization.zonesWithARenewableSubscription,
        organization.availablePaidProducts,
        availablePaidAddons,
        state.userInput.wantsForever
      );

      const saleOrderProductIds = Array.from(new Set(cartItemsToAdd.map((cartItem) => cartItem.productId)));

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedZones: organization.zonesWithARenewableSubscription,
        },
        organization,
        zones: organization.zonesWithARenewableSubscription,
        availableProducts: organization.availablePaidProducts,
        availablePaidAddons,
        cartItemsToAdd,
        saleOrderProductIds,
      };
    }

    case RenewPurchaseAction.SELECT_ZONE: {
      const { zone } = payload;

      const cartItemsToAdd = buildCartItems(
        [zone],
        state.zones,
        state.availableProducts,
        state.availablePaidAddons,
        state.userInput.wantsForever
      );

      const saleOrderProductIds = Array.from(
        new Set([...state.saleOrderProductIds, ...cartItemsToAdd.map((cartItem) => cartItem.productId)])
      );

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedZones: [...state.userInput.selectedZones, zone],
        },
        cartItemsToAdd,
        saleOrderProductIds,
      };
    }

    case RenewPurchaseAction.DESELECT_ZONE: {
      const { zone } = payload;

      const cartItemIdsToRemove = state.saleOrder.saleOrderLines
        .filter((saleOrderLine) => saleOrderLine.zone.id === zone.id)
        .map((saleOrderLine) => saleOrderLine.cartLineId);

      const remainingSaleOrderProductIds = state.saleOrder.saleOrderLines
        .filter((saleOrderLine) => !cartItemIdsToRemove.includes(saleOrderLine.cartLineId))
        .map((saleOrderLine) => Number(saleOrderLine.product.id));

      const saleOrderProductIds = Array.from(new Set(remainingSaleOrderProductIds));

      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedZones: state.userInput.selectedZones.filter((selectedZone) => selectedZone.id !== zone.id),
        },
        cartItemIdsToRemove,
        saleOrderProductIds,
      };
    }

    case RenewPurchaseAction.SELECT_PAYMENT_OPTION: {
      const { paymentOption } = payload;
      return {
        ...state,
        userInput: {
          ...state.userInput,
          selectedPaymentOption: paymentOption,
        },
      };
    }

    case RenewPurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH: {
      const { wantsForever } = payload;

      const cartItemIdsToRemove = state.saleOrder.saleOrderLines.map((saleOrderLine) => saleOrderLine.cartLineId);
      const cartItemsToAdd = buildCartItems(
        state.userInput.selectedZones,
        state.zones,
        state.availableProducts,
        state.availablePaidAddons,
        wantsForever
      );

      const saleOrderProductIds = Array.from(new Set(cartItemsToAdd.map((cartItem) => cartItem.productId)));

      return {
        ...state,
        userInput: {
          ...state.userInput,
          wantsForever,
        },
        cartItemsToSwitch: { cartItemIdsToRemove, cartItemsToAdd },
        saleOrderProductIds,
      };
    }

    case RenewPurchaseAction.FETCHED_PAYMENT_OPTIONS: {
      const { paymentOptions } = payload;

      const paymentOptionIsAvailable = paymentOptions.some(
        (paymentOption) => paymentOption.id === state.userInput.selectedPaymentOption?.id
      );

      return {
        ...state,
        availablePaymentOptions: paymentOptions,
        userInput: {
          ...state.userInput,
          selectedPaymentOption: paymentOptionIsAvailable ? state.userInput.selectedPaymentOption : null,
        },
      };
    }

    case RenewPurchaseAction.ADDED_ITEMS_TO_CART: {
      const { saleOrder } = payload;
      return {
        ...state,
        saleOrder,
        cartItemsToAdd: [],
      };
    }

    case RenewPurchaseAction.REMOVED_ITEMS_FROM_CART: {
      const { saleOrder } = payload;
      return {
        ...state,
        saleOrder,
        cartItemIdsToRemove: [],
      };
    }

    case RenewPurchaseAction.SWITCHED_CART_ITEMS: {
      const { saleOrder } = payload;
      return {
        ...state,
        saleOrder,
        cartItemsToSwitch: { cartItemsToAdd: [], cartItemIdsToRemove: [] },
      };
    }

    case RenewPurchaseAction.CLEAR_STATE: {
      return initialRenewPurchaseState;
    }

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
