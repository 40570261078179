import Cookies from 'js-cookie';
import qs from 'query-string';
import config from '../../../config';

const LANGUAGE_COOKIE_KEY = config.LANGUAGE_COOKIE_KEY;
const LANGUAGE_FALLBACK = config.LANGUAGE_FALLBACK;
const LANGUAGE_REDIRECT_URL = config.LANGUAGE_REDIRECT_URL;
const LANGUAGE_REDIRECT_ENABLED = config.LANGUAGE_REDIRECT;
const LANGUAGE_COOKIE_MAX_AGE = config.LANGUAGE_COOKIE_MAX_AGE;
const LANGUAGE_COOKIE_DOMAIN = config.LANGUAGE_COOKIE_DOMAIN;
const LANGUAGE_IS_REDIRECTED_KEY = config.LANGUAGE_IS_REDIRECTED_KEY;
const BASE_URL = config.BASE_URL;

// Ping the url
const ping = async (url) =>
  window
    .fetch(url, { mode: 'no-cors' })
    .then((response) => {
      if (!response.status === 302) return false;
      return true;
    })
    .catch(() => {
      console.log('[lang] Unable to ping the language redirect url.');
      return false;
    });

export const getRemoteLangCookie = () =>
  ping(LANGUAGE_REDIRECT_URL).then((isServerUp) => {
    if (isServerUp) {
      Cookies.set(LANGUAGE_IS_REDIRECTED_KEY, 'false', {
        domain: LANGUAGE_COOKIE_DOMAIN,
        expires: new Date(new Date().getTime() + LANGUAGE_COOKIE_MAX_AGE),
      });
      if (Cookies.get(LANGUAGE_IS_REDIRECTED_KEY)) {
        Cookies.set(LANGUAGE_IS_REDIRECTED_KEY, 'true', {
          domain: LANGUAGE_COOKIE_DOMAIN,
          expires: new Date(new Date().getTime() + LANGUAGE_COOKIE_MAX_AGE),
        });
        // set the lang cookie in development
        if (process.env.REACT_APP_ENV === 'development') {
          Cookies.set(LANGUAGE_COOKIE_KEY, 'nl-be');
        } else {
          // Use current url with query params in redirect-url for if sonos link is used
          const queryParams = qs.parseUrl(window.location.href).query;
          const queryString = Object.keys(queryParams)
            .map(
              (queryParam) =>
                `${queryParam}=${encodeURIComponent(queryParams[queryParam])}`
            )
            .join('&');
          const currentUrl = `${BASE_URL + window.location.pathname}${
            queryString ? `?${queryString}` : ''
          }`;
          window.location.href = `${LANGUAGE_REDIRECT_URL}?redirect-url=${currentUrl}`;
        }
      }
      console.log('[lang] In production cookie should be set');
      return Cookies.get(LANGUAGE_COOKIE_KEY);
    }
    console.error(
      '[lang] Language redirection server is not online. Will use the fallback language.'
    );
    return LANGUAGE_FALLBACK;
  });

export const getLangCookie = async () => {
  // redirect flag not enabled
  if (!LANGUAGE_REDIRECT_ENABLED) {
    console.log(
      '[lang] Language redirection is not allowed. Will use the fallback language.'
    );
    return LANGUAGE_FALLBACK;
  }
  // already tried getting a lang cookie
  // eslint-disable-next-line eqeqeq
  if (Cookies.get(LANGUAGE_IS_REDIRECTED_KEY) == true) {
    console.log(
      '[lang] User just came from the redirection, language cookie should be set now'
    );
    // This will set the cookie that was given back by the webhook. This is done manually because
    // the lang cookie is set for the tunify.com domain, and ofc localhost is not part of this.
    if (process.env.REACT_APP_ENV === 'development') {
      return LANGUAGE_FALLBACK;
    }
    return LANGUAGE_FALLBACK;
  }

  console.log('[lang] Language redirection is allowed. Will redirect now.');
  const result = (await getRemoteLangCookie()) ?? null;
  return result;
};
