import { useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { subMonths, subWeeks } from 'date-fns';
import styled from 'styled-components';

import {
  Page,
  ErrorMessage,
  LoadingIndicator,
  useToggle,
  ZoneSelectionModal,
} from '../../../common';
import {
  GET_MUSIC_REPORT,
  GET_ZONES,
  GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS,
} from './api';
import Filter from './filter';
import MusicReport from './musicReport';
import PageHeader from './pageHeader';
import MusicHistory from '../musicHistory';

const Container = styled.div`
  display: grid;
  grid-template-rows: min-content 1fr;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    height: 100vh;
    @supports (height: 100svh) {
      height: 100svh;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  align-content: start;
  overflow-y: auto;
  padding: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 3rem 3rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    padding: 3rem 4rem;
  }
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 3rem 4.8rem;
  }
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
`;

const MusicReportPage = () => {
  const { t } = useTranslation();

  const [selectedCategory, setSelectedCategory] = useState('report');
  const [confirmedZoneIds, setConfirmedZoneIds] = useState([]);
  const [tempZoneIds, setTempZoneIds] = useState([]);
  const [selectedPeriodId, setSelectedPeriodId] = useState('lastMonth');

  const [zoneSelectionModalIsOpen, toggleZoneSelectionModal] = useToggle(false);

  const getAfterDateString = useCallback((periodId) => {
    switch (periodId) {
      case 'lastWeek': {
        return subWeeks(new Date(), 1).toISOString();
      }
      case 'lastMonth': {
        return subMonths(new Date(), 1).toISOString();
      }
      default:
        throw new Error(`Unknown period: ${periodId}`);
    }
  }, []);

  const beforeDateString = useMemo(() => new Date().toISOString(), []);

  const [
    getMusicReport,
    {
      loading: musicReportLoading,
      data: musicReportData,
      error: musicReportError,
    },
  ] = useLazyQuery(GET_MUSIC_REPORT, { fetchPolicy: 'no-cache' });

  const {
    loading: zonesLoading,
    error: zonesError,
    data: zonesData,
  } = useQuery(GET_ZONES);

  const { data: dataNewZoneIds, loading: loadingNewZoneIds } = useQuery(
    GET_USER_IDS_FROM_EXTERNAL_ZONE_IDS,
    {
      variables: {
        zoneIds: zonesData?.zones?.map((zone) => zone.id),
      },
      skip: !zonesData?.zones?.length > 0,
      onCompleted: (data) => {
        // At mount we select all zones
        if (data?.userIdsFromExternalZoneIds?.length > 0) {
          const zoneIds = data.userIdsFromExternalZoneIds.map(
            (item) => item.zoneId
          );
          setConfirmedZoneIds(
            data.userIdsFromExternalZoneIds.map(
              (item) => `${item.externalZoneId}`
            )
          );
          setTempZoneIds(
            data.userIdsFromExternalZoneIds.map(
              (item) => `${item.externalZoneId}`
            )
          );
          getMusicReport({
            variables: {
              zoneIds,
              afterDateString: getAfterDateString(selectedPeriodId),
              beforeDateString,
            },
          });
        }
      },
    }
  );

  const periodOptions = [
    {
      value: 'lastWeek',
      label: t('musicManagement:musicReport.filter.lastWeek'),
    },
    {
      value: 'lastMonth',
      label: t('musicManagement:musicReport.filter.lastMonth'),
    },
  ];

  const showReport = useCallback(
    (period, zoneIds) => {
      setSelectedPeriodId(period.value);
      if (zoneIds.length !== 0) {
        setConfirmedZoneIds(zoneIds);
      }
      const newZoneIds = dataNewZoneIds?.userIdsFromExternalZoneIds
        ?.filter((item) => zoneIds.includes(`${item.externalZoneId}`))
        .map((item) => item.zoneId);
      getMusicReport({
        variables: {
          zoneIds: newZoneIds,
          afterDateString: getAfterDateString(period.value),
          beforeDateString,
        },
      });
    },
    [getMusicReport, beforeDateString, getAfterDateString, dataNewZoneIds]
  );

  const confirmSelectedZoneIds = useCallback(
    (zones) => {
      if (zones.length !== 0) {
        const zoneIds = zones.map((zone) => zone.id);
        setTempZoneIds(zoneIds);
      }
      toggleZoneSelectionModal();
    },
    [toggleZoneSelectionModal]
  );

  return (
    <Page title={t('musicManagement:musicReport.title')}>
      <Container>
        <PageHeader
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <Grid>
          {selectedCategory === 'report' ? (
            <>
              <Filter
                hasAllZonesSelected={
                  confirmedZoneIds.length === zonesData?.zones?.length
                }
                hasAllTempZonesSelected={
                  tempZoneIds.length === zonesData?.zones?.length
                }
                musicReportLoading={
                  musicReportLoading || zonesLoading || loadingNewZoneIds
                }
                periodOptions={periodOptions}
                selectedPeriodId={selectedPeriodId}
                selectedZones={
                  zonesData?.zones?.filter((zone) =>
                    confirmedZoneIds.includes(zone.id)
                  ) ?? []
                }
                tempSelectedZones={
                  zonesData?.zones?.filter((zone) =>
                    tempZoneIds.includes(zone.id)
                  ) ?? []
                }
                toggleZoneSelectionModal={toggleZoneSelectionModal}
                setTempZoneIds={setTempZoneIds}
                showReport={showReport}
              />
              {(musicReportLoading || zonesLoading || loadingNewZoneIds) && (
                <LoadingIndicator />
              )}
              {(musicReportError || zonesError) && (
                <StyledErrorMessage error={musicReportError || zonesError} />
              )}
              {!musicReportError && !musicReportLoading && musicReportData && (
                <MusicReport musicReportData={musicReportData} />
              )}
            </>
          ) : null}
          {selectedCategory === 'musicHistory' ? <MusicHistory /> : null}
          {selectedCategory === 'suggestions' ? <></> : null}
        </Grid>
      </Container>
      <ZoneSelectionModal
        defaultSelected={
          zonesData?.zones?.filter((zone) => tempZoneIds.includes(zone.id)) ??
          []
        }
        error={zonesError}
        instructions={t(
          'musicManagement:musicReport.zoneSelectionModal.instructions'
        )}
        isMulti
        isOpen={zoneSelectionModalIsOpen}
        onClose={toggleZoneSelectionModal}
        onConfirm={confirmSelectedZoneIds}
        title={t('musicManagement:musicReport.zoneSelectionModaltitle')}
        zones={zonesData?.zones?.map((zone) => ({
          ...zone,
          organizationName: zone.location.organization.name,
          locationName: zone.location.name,
        }))}
      />
    </Page>
  );
};

export default MusicReportPage;
