import React, { lazy } from 'react';
import { Route } from 'react-router';
import {
  ACCOUNTANT_ROLE_PERMISSIONS,
  OWNER_ROLE_PERMISSIONS,
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  PARTNER_ROLE_PERMISSIONS,
  SUPPORT_ROLE_PERMISSIONS,
} from '../../../global/auth';
import { AuthGuardPermissions } from '../../../global/auth/authGuardPermissions';
import { Dashboard } from '../../../modules/dashboard';
import { MusicManagementRouter } from '../../musicManagement/router';

import CustomerSuccessLayout from '../../customerSuccess';
import { CustomerSuccessRouter } from '../../customerSuccess/router';
import { OrganizationRouter } from '../../organization/router';
import { PartnerLayout } from '../../partner';
import { PartnerRouter } from '../../partner/router';
import { PartnerManagerLayout } from '../../partnerManager';
import { PartnerManagerRouter } from '../../partnerManager/router';
import { PaymentFeedbackRouter } from '../../paymentFeedback/router';
import { ImpersonateRouter } from '../../impersonate/router';
import { ImpersonateLayout } from '../../impersonate';
import { MusicPlaylistLayout } from '../../musicPlaylist';
import { MusicPlaylistRouter } from '../../musicPlaylist/router';
import { MusicReportLayout } from '../../musicReport';
import { MusicReportRouter } from '../../musicReport/router';
import { integrationRoutesBehindAuthentication } from '../../integrations/router';
import IntegrationLayout from '../../integrations';
import ZoneRedirectShortcut from '../../../modules/zones/zoneRedirectShortcut';

const Profile = lazy(() => import('../../../modules/profile'));
const Home = lazy(() => import('../../../modules/home'));
const InvoiceOverview = lazy(() =>
  import('../../../modules/invoices/overview')
);
const GiftOverview = lazy(() => import('../../../modules/gifts/overview'));
const SubscriptionOverview = lazy(() =>
  import('../../../modules/subscriptions')
);
const AddonOverview = lazy(() => import('../../../modules/addons/overview'));
const BroadcastsOverview = lazy(() =>
  import('../../../modules/broadcasts/overview')
);
const MusicManagementLayout = lazy(() => import('../../musicManagement'));
const OrganizationLayout = lazy(() => import('../../organization'));
const PaymentFeedbackLayout = lazy(() => import('../../paymentFeedback'));

export const RootLayoutBehindAuthentication = [
  <Route index element={<Home />} />,
  <Route path="dashboard" element={<Dashboard />} />,
  <Route path="profile" element={<Profile />} />,
  <Route
    path="invoices"
    element={
      <AuthGuardPermissions permissions={ACCOUNTANT_ROLE_PERMISSIONS}>
        <InvoiceOverview />
      </AuthGuardPermissions>
    }
  />,
  <Route
    path="gifts"
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <GiftOverview />
      </AuthGuardPermissions>
    }
  />,
  <Route
    path="subscriptions"
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <SubscriptionOverview />
      </AuthGuardPermissions>
    }
  />,
  <Route
    path="add-ons"
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <AddonOverview />
      </AuthGuardPermissions>
    }
  />,
  <Route
    path="broadcasts"
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <BroadcastsOverview />
      </AuthGuardPermissions>
    }
  />,
  <Route path="music-management" element={<MusicManagementLayout />}>
    {MusicManagementRouter}
  </Route>,
  <Route path="playlists" element={<MusicPlaylistLayout />}>
    {MusicPlaylistRouter}
  </Route>,
  <Route path="music-report" element={<MusicReportLayout />}>
    {MusicReportRouter}
  </Route>,
  <Route
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <OrganizationLayout />
      </AuthGuardPermissions>
    }
    path="organizations"
  >
    {OrganizationRouter}
  </Route>,
  <Route
    path="payment-feedback"
    element={
      <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
        <PaymentFeedbackLayout />
      </AuthGuardPermissions>
    }
  >
    {PaymentFeedbackRouter}
  </Route>,
  <Route
    path="customer-success"
    element={
      <AuthGuardPermissions permissions={SUPPORT_ROLE_PERMISSIONS}>
        <CustomerSuccessLayout />
      </AuthGuardPermissions>
    }
  >
    {CustomerSuccessRouter}
  </Route>,
  <Route
    path="partner"
    element={
      <AuthGuardPermissions permissions={PARTNER_ROLE_PERMISSIONS}>
        <PartnerLayout />
      </AuthGuardPermissions>
    }
  >
    {PartnerRouter}
  </Route>,
  <Route
    path="partner-manager"
    element={
      <AuthGuardPermissions permissions={PARTNER_MANAGER_ROLE_PERMISSIONS}>
        <PartnerManagerLayout />
      </AuthGuardPermissions>
    }
  >
    {PartnerManagerRouter}
  </Route>,
  <Route path="impersonation/user/:id" element={<ImpersonateLayout />}>
    {ImpersonateRouter}
  </Route>,
  <Route path="integrations" element={<IntegrationLayout />}>
    {integrationRoutesBehindAuthentication}
  </Route>,
  <Route path="direct/zones/:zoneId" element={
    <AuthGuardPermissions permissions={OWNER_ROLE_PERMISSIONS}>
      <ZoneRedirectShortcut />
    </AuthGuardPermissions>} />
];
