import { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useVirtual } from 'react-virtual';
import { createPortal } from 'react-dom';
import { DragOverlay } from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  ErrorMessage,
  LoadingIndicator,
  capitalize,
  useToggle,
  useMediaSize,
  ZoneSelectionModal,
  ItemMeasurer,
  useOnClickOutside,
  DeleteModal,
} from '../../../common';
import { DraggableSong } from './song/draggableSong';
import { Song } from './song/song';
import {
  GET_SONGS_OF_PLAYLIST,
  DELETE_CUSTOM_PLAYLIST,
  COPY_PLAYLISTS_TO_OTHER_ZONES,
  GET_SONGS_OF_MUSIC_COLLECTION,
  SAVE_CUSTOM_PLAYLIST,
  GET_MUSIC_COLLECTION_CONTEXT_BY_ZONE,
} from './api';
import { ReactComponent as SortIcon } from '../../../assets/icons/sortList.svg';
import { ReactComponent as SongInfoIcon } from '../../../assets/icons/songInfo.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/icons/refresh.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings.svg';
import { ReactComponent as AddToListIcon } from '../../../assets/icons/addToList.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrowRight.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as TrashIcon } from '../../../assets/icons/delete.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { ReactComponent as MoreOptionsIcon } from '../../../assets/icons/options.svg';
import {
  PlaylistModal,
  PlaylistSelectionModal,
  CollectionSettingsModal,
  SongInfoSelectionModal,
} from './modals';
import { useUpdatePlaylistCacheAfterRemove } from './cache';
import { usePlaylists } from './playlistsProvider';
import { PlaylistModalModes, PlaylistTypes } from './types';

import theme from '../../../global/style/theme';

const PlaylistHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    margin: 0 1.2rem;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    margin: 0.5rem 0.5rem 0.5rem 0;
    padding: 0;
    border-bottom: none;
    grid-template-columns: 1fr min-content;
    align-items: center;
  }
`;

const HeaderWrapper = styled.div`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    height: 7rem;
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: center;
    padding: 0 1.6rem;
  }
`;

const MobileSubHeader = styled.div`
  height: 6rem;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 0 0.3rem 0 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 0 1.8rem 0 1.2rem;
  }
`;

const SongSelectSection = styled.div`
  height: 6rem;
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  gap: 1.6rem;
  padding: 0 1.2rem 0 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    height: unset;
    padding: 0.5rem 0.9rem;
  }
`;

const RightSongSelect = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 0.8rem;
  align-items: center;
`;

const SubHeader = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  gap: 0.8rem;
  align-items: center;
  padding: 0.9rem;
`;

const PlaylistTitle = styled.h3`
  grid-column: span 2;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ $isSearch, theme }) =>
    $isSearch &&
    css`
      font-size: 1.3rem;
      color: ${theme.dark300};
      font-weight: 500;
    `}

  ${({ $isMobile }) =>
    $isMobile &&
    css`
      grid-column: unset;
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.medium}px) {
    grid-column: unset;
    overflow: visible;
  }
`;

const IconWrapper = styled.div`
  display: grid;
  padding: 0 0.6rem;
  cursor: pointer;

  & svg:hover {
    color: ${({ theme, deleteHover }) =>
      deleteHover ? theme.grapefruitRed : theme.waterBlue};
  }
`;

const IconWrapperWithInfo = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1.2rem;
  cursor: pointer;

  & svg,
  p {
    color: ${({ theme }) => theme.waterBlue};
  }

  & p {
    font-size: 1.3rem;
    white-space: nowrap;
  }
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  margin: 2rem 0;
`;

const SmallLoadingIndicator = styled(LoadingIndicator)`
  margin: 0 0.7rem;
`;

const Info = styled.div`
  color: ${({ theme }) => theme.dark300};
  font-size: 1.4rem;
  line-height: 1.4;
`;

const ScrollContainer = styled.div`
  ${({ $extraHeight }) =>
    $extraHeight
      ? css`
          height: calc(100vh - 18.5rem);
        `
      : css`
          height: calc(100vh - 23.5rem);
        `}

  display: grid;
  grid-auto-rows: min-content;
  overflow-y: auto;
  user-select: none;
  padding: 0 1rem 0.6rem 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    height: calc(100vh - 17rem);
    padding: 0 1.6rem 0.6rem 1.6rem;
  }
`;

const StyledArrowIcon = styled(ArrowIcon)`
  transform: rotate(180deg);
  margin: 0.5rem;
`;

const TopWrapper = styled.div`
  height: 6rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr min-content;
  align-items: center;
  padding: 0 1.2rem 0 0.4rem;
  column-gap: 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 0;
    grid-auto-columns: min-content;
    grid-template-columns: min-content min-content;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    height: unset;
    padding: 1rem 0.6rem;
    grid-template-columns: min-content min-content 1fr min-content;
  }
`;

const TopLeftBase = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 0.4rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    gap: 0;
    grid-template-columns: 1fr;
  }
`;

const TopLeft = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;
  align-items: center;
  gap: 0.8rem;

  ${({ $moreOptionsPopupIsOpen }) =>
    $moreOptionsPopupIsOpen &&
    css`
      background-color: ${({ theme }) => theme.uiBackground};
      border: 1px solid ${({ theme }) => theme.stroke};
      border-radius: 4px;
      padding: 1.4rem 0.6rem;
    `}
`;

const SongAmount = styled.p`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.dark300};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.large}px) {
    justify-self: end;
  }
`;

const VirtualRow = styled.div`
  height: ${({ $totalSize }) => $totalSize}px;
  width: 100%;
  position: relative;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  width: 100%;
`;

const SongWrapper = styled.div.attrs(
  // This fixes an issue with vscode-styled-components
  (props) => ({
    style: {
      top: `${props.start}px`,
    },
  })
)`
  width: 100%;
  position: absolute;
  left: 0;
`;

const Divider = styled.div`
  width: 0.1rem;
  height: 90%;
  background-color: ${({ theme }) => theme.stroke};
`;

const sortByArray = ['', 'title', 'group', 'lastValue'];

const PlaylistDetail = ({ setTab }) => {
  const { t } = useTranslation();

  const {
    dispatch,
    selectedZone,
    selectedPlaylist,
    zones,
    songs,
    activeId,
    selectedPlaylistType,
    errorSearchSongs,
    loadingSearchSongs,
    lastSongValue,
    isSongSelectionActivated,
    selectedSongs,
    refetchCustomPlaylists,
    changeableParameters,
  } = usePlaylists();

  const [zoneMultiSelectionModalIsOpen, toggleZoneMultiSelectionModal] =
    useToggle();
  const [isAddToListPopUpOpen, toggleAddToListPopup] = useToggle(false);
  const [areSettingsOpen, setAreSettingsOpen] = useState(false);
  const [isSongInfoSelectionOpen, setSongInfoSelectionOpen] = useState(false);
  const [moreOptionsPopupIsOpen, setMoreOptionsPopup] = useState(false);

  const [sortBy, setSortBy] = useState('');

  const { isNarrowerThanMedium, isNarrowerThanLarge, isWiderThanMedium } =
    useMediaSize();

  const parentRef = useRef();

  const handleCloseSongSelectionMode = useCallback(() => {
    dispatch({
      isSongSelectionActivated: false,
      selectedSongs: [],
    });
  }, [dispatch]);

  useEffect(() => {
    handleCloseSongSelectionMode();
  }, [selectedPlaylist, handleCloseSongSelectionMode]);

  const [saveCustomPlaylist, { loading: loadingSavePlaylist }] = useMutation(
    SAVE_CUSTOM_PLAYLIST,
    {
      onCompleted: () => {
        dispatch({
          songs: songs.filter((i) => !selectedSongs.find((s) => s.id === i.id)),
          selectedSongs: [],
          isSongSelectionActivated: false,
        });
      },
    }
  );

  const handleDeleteSelectedSongs = useCallback(() => {
    saveCustomPlaylist({
      variables: {
        zoneId: selectedZone.id,
        playlistId: selectedPlaylist.id,
        playlist: {
          id: selectedPlaylist.id,
          title: selectedPlaylist.title,
          audioFiles: songs
            .filter((i) => !selectedSongs.find((s) => s.id === i.id))
            .map((i) => ({ id: i.songId, title: i.title })),
        },
      },
    });
  }, [
    selectedZone.id,
    songs,
    selectedPlaylist,
    selectedSongs,
    saveCustomPlaylist,
  ]);

  const { loading, error } = useQuery(GET_SONGS_OF_PLAYLIST, {
    variables: {
      playlistId: selectedPlaylist.id,
      zoneId: selectedZone.id,
    },
    skip: !selectedPlaylist.id || selectedPlaylistType === PlaylistTypes.TUNIFY,
    onCompleted: (data) => {
      if (data?.songsOfPlaylist) {
        dispatch({
          songs: data.songsOfPlaylist.map((song, index) => ({
            ...song,
            id: `${song.id}${index}`,
            songId: song.id,
          })),
        });
      }
    },
  });

  const {
    loading: loadingSongsOfMusicCollection,
    error: errorSongsOfMusicCollection,
    refetch: refetchSongsOfMusicCollection,
  } = useQuery(GET_SONGS_OF_MUSIC_COLLECTION, {
    variables: {
      musicCollectionId: selectedPlaylist.musicCollectionId,
      musicChannelId: selectedPlaylist.musicChannelId,
      zoneId: selectedZone.id,
    },
    skip:
      !selectedPlaylist.musicCollectionId ||
      !selectedPlaylist.musicChannelId ||
      selectedPlaylistType === PlaylistTypes.CUSTOM,
    onCompleted: (data) => {
      if (data?.songsOfMusicCollection) {
        dispatch({
          songs: data.songsOfMusicCollection.map((song, index) => ({
            ...song,
            id: `${song.id}${index}`,
            songId: song.id,
          })),
        });
      }
    },
  });

  const handleSortSongs = useCallback(() => {
    let index = sortByArray.findIndex((v) => v === sortBy) + 1;
    if (index >= sortByArray.length) {
      index = 0;
    }
    setSortBy(sortByArray[index]);
  }, [setSortBy, sortBy]);

  const handleClosePlaylist = useCallback(() => {
    dispatch({
      selectedPlaylist: null,
      tempSearchValue: '',
    });
  }, [dispatch]);

  const [deletePlaylistModalIsOpen, toggleDeletePlaylistModalIsOpen] =
    useToggle();
  const [modalMode, setModalMode] = useState();

  const handleUpdatePlaylistCacheAfterRemove =
    useUpdatePlaylistCacheAfterRemove(
      { zoneId: selectedZone.id },
      selectedPlaylist?.id
    );

  const [
    deleteCustomPlaylist,
    { loading: deletePlaylistLoading, error: deletePlaylistError },
  ] = useMutation(DELETE_CUSTOM_PLAYLIST, {
    update: handleUpdatePlaylistCacheAfterRemove,
    onCompleted: (dataDelete) => {
      if (dataDelete?.deleteCustomPlaylist?.updateInfos) {
        refetchCustomPlaylists();
      }
      dispatch({
        selectedPlaylist: null,
      });
      toggleDeletePlaylistModalIsOpen();
    },
  });

  const handleDeletePlaylist = useCallback(() => {
    deleteCustomPlaylist({
      variables: {
        zoneId: selectedZone.id,
        playlistId: selectedPlaylist?.id,
        customPlaylistsTimestamp: selectedPlaylist?.timestamp,
      },
    });
  }, [selectedZone.id, selectedPlaylist, deleteCustomPlaylist]);

  const handleOpenEditPlaylist = useCallback(() => {
    setModalMode(PlaylistModalModes.EDIT);
  }, []);

  const handleClosePlaylistModal = useCallback(() => {
    setModalMode(null);
  }, []);

  const handleClosePlaylistSelectionModal = useCallback(() => {
    if (isAddToListPopUpOpen) {
      toggleAddToListPopup();
    }
  }, [isAddToListPopUpOpen, toggleAddToListPopup]);

  const openSongInfoSelectionModal = useCallback(() => {
    setSongInfoSelectionOpen(true);
  }, []);

  const closeSongInfoSelectionModal = useCallback(() => {
    setSongInfoSelectionOpen(false);
  }, []);

  const [
    copyPlaylistsToOtherZones,
    { loading: loadingCopyPlaylists, error: errorCopyPlaylists },
  ] = useMutation(COPY_PLAYLISTS_TO_OTHER_ZONES, {
    onCompleted: toggleZoneMultiSelectionModal,
  });

  const handleConfirmSelection = useCallback(
    (selectedZones) => {
      copyPlaylistsToOtherZones({
        variables: {
          currentZoneId: selectedZone.id,
          playlistIds: [selectedPlaylist.id],
          zoneIds: selectedZones.map((zone) => zone.id),
        },
      });
    },
    [selectedPlaylist, selectedZone.id, copyPlaylistsToOtherZones]
  );

  const handleRefetch = useCallback(() => {
    refetchSongsOfMusicCollection();
  }, [refetchSongsOfMusicCollection]);

  const {
    loading: loadingCollectionSettings,
    data: dataCollectionSettings,
    error: errorCollectionSettings,
  } = useQuery(GET_MUSIC_COLLECTION_CONTEXT_BY_ZONE, {
    variables: {
      zoneId: selectedZone.id,
      musicCollectionId: selectedPlaylist.musicCollectionId,
    },
    skip: !selectedZone?.id || !selectedPlaylist?.musicCollectionId,
    onCompleted: (dataSettings) => {
      if (dataSettings?.musicCollectionContextByZone?.changeableParameter) {
        const changeableParametersWithoutTypename =
          dataSettings.musicCollectionContextByZone.changeableParameter.map(
            ({ __typename, ...parameters }) => ({
              ...parameters,
              value: parameters?.value?.map(
                ({ __typename: typenameValue, ...value }) => value
              ),
            })
          );
        dispatch({
          changeableParameters: changeableParametersWithoutTypename,
        });
      } else {
        dispatch({
          changeableParameters: [],
        });
      }
    },
  });

  const isPlaylist =
    !!selectedPlaylist?.id && selectedPlaylistType === PlaylistTypes.CUSTOM;

  const sortedSongs = useMemo(() => {
    if (sortBy) {
      const sortField = sortBy === 'lastValue' ? lastSongValue : sortBy;
      return [...songs].sort((a, b) => {
        return a[sortField] > b[sortField] ? 1 : -1;
      });
    }
    return songs;
  }, [songs, sortBy, lastSongValue]);

  const rowVirtualizer = useVirtual({
    size: sortedSongs.length,
    parentRef,
    overscan: 10,
  });

  const activeItem = useMemo(
    () => (activeId ? songs.find((song) => song?.songId === activeId) : null),
    [songs, activeId]
  );

  const partialZones = useMemo(
    () =>
      zones
        ?.filter((zone) => zone?.id !== selectedZone?.id)
        ?.map((zone) => ({
          ...zone,
          organizationName: zone.organizationName,
          locationName: zone.location.name,
        })),
    [zones, selectedZone?.id]
  );

  const handleCloseZones = useCallback(() => {
    if (zoneMultiSelectionModalIsOpen) {
      toggleZoneMultiSelectionModal();
    }
  }, [zoneMultiSelectionModalIsOpen, toggleZoneMultiSelectionModal]);

  const errorMessage = useMemo(() => {
    if (selectedPlaylistType === PlaylistTypes.CUSTOM) {
      return error;
    }
    if (selectedPlaylistType === PlaylistTypes.TUNIFY) {
      return errorSongsOfMusicCollection;
    }
    return errorSearchSongs;
  }, [
    selectedPlaylistType,
    error,
    errorSongsOfMusicCollection,
    errorSearchSongs,
  ]);

  const handleOpenSettings = useCallback(() => {
    setAreSettingsOpen(true);
  }, []);

  const handleCloseSettings = useCallback(() => {
    setAreSettingsOpen(false);
  }, []);

  const moreOptionsRef = useRef();

  const handleCloseMoreOptions = useCallback(() => {
    setMoreOptionsPopup(false);
  }, []);

  useOnClickOutside(moreOptionsRef, handleCloseMoreOptions);

  const handleToggleMoreOptions = useCallback(() => {
    setMoreOptionsPopup((prev) => !prev);
  }, []);

  return (
    <>
      <HeaderWrapper>
        {!(
          isNarrowerThanMedium && selectedPlaylistType === PlaylistTypes.SEARCH
        ) ? (
          <PlaylistHeader>
            <TopWrapper>
              <TopLeftBase>
                {isNarrowerThanMedium &&
                selectedPlaylistType !== PlaylistTypes.SEARCH ? (
                  <IconWrapper onClick={handleClosePlaylist}>
                    <StyledArrowIcon
                      height="1.6rem"
                      width="1.6rem"
                      color={theme.dark300}
                    />
                  </IconWrapper>
                ) : null}
                <PlaylistTitle
                  $isSearch={selectedPlaylistType === PlaylistTypes.SEARCH}
                >
                  {selectedPlaylist?.title ? (
                    <>
                      {typeof selectedPlaylist.title === 'string'
                        ? capitalize(selectedPlaylist.title)
                        : selectedPlaylist.title}
                    </>
                  ) : (
                    ''
                  )}
                </PlaylistTitle>
              </TopLeftBase>
              <TopLeft
                $moreOptionsPopupIsOpen={moreOptionsPopupIsOpen}
                ref={moreOptionsRef}
              >
                {isWiderThanMedium || moreOptionsPopupIsOpen ? (
                  <>
                    {selectedPlaylistType === PlaylistTypes.CUSTOM && (
                      <>
                        {!!selectedPlaylist?.id ? (
                          <IconWrapper onClick={handleOpenEditPlaylist}>
                            <EditIcon
                              height="1.6rem"
                              width="1.6rem"
                              color={theme.dark300}
                            />
                          </IconWrapper>
                        ) : null}
                        {!!selectedPlaylist?.id ? (
                          <>
                            <Divider />
                            <IconWrapper
                              onClick={toggleDeletePlaylistModalIsOpen}
                              deleteHover
                            >
                              <TrashIcon
                                height="1.8rem"
                                width="1.8rem"
                                color={theme.dark300}
                              />
                            </IconWrapper>
                          </>
                        ) : null}
                        {isPlaylist && songs.length > 0 ? (
                          <>
                            <Divider />
                            <IconWrapper
                              onClick={toggleZoneMultiSelectionModal}
                              title={t(
                                'integrations:spotify.import.importButtonTitle'
                              )}
                            >
                              <ShareIcon
                                height="1.6rem"
                                width="1.6rem"
                                color={theme.dark300}
                              />
                            </IconWrapper>
                          </>
                        ) : null}
                      </>
                    )}
                    {selectedPlaylistType === PlaylistTypes.TUNIFY && (
                      <>
                        {selectedPlaylist?.id &&
                        changeableParameters?.length > 0 ? (
                          <>
                            <IconWrapper onClick={handleOpenSettings}>
                              <SettingsIcon
                                height="1.6rem"
                                width="1.6rem"
                                color={theme.dark300}
                              />
                            </IconWrapper>
                            <Divider />
                          </>
                        ) : null}
                        {selectedPlaylist?.id ? (
                          <IconWrapper onClick={handleRefetch}>
                            <RefreshIcon
                              height="1.6rem"
                              width="1.6rem"
                              color={theme.dark300}
                            />
                          </IconWrapper>
                        ) : null}
                      </>
                    )}
                  </>
                ) : (
                  <IconWrapper isDiv onClick={handleToggleMoreOptions}>
                    <MoreOptionsIcon
                      color={theme.dark300}
                      height="1.8rem"
                      width="1.8rem"
                    />
                  </IconWrapper>
                )}
              </TopLeft>
              {!isNarrowerThanLarge ? (
                <>
                  {!isSongSelectionActivated ? (
                    <SongAmount>
                      {t('musicManagement:playlists.list.header.songs', {
                        amount: songs.length,
                      })}
                    </SongAmount>
                  ) : (
                    <div />
                  )}
                </>
              ) : null}
            </TopWrapper>
          </PlaylistHeader>
        ) : null}

        {isSongSelectionActivated ? (
          <SongSelectSection>
            <IconWrapperWithInfo
              onClick={handleCloseSongSelectionMode}
              title={t('integrations:spotify.import.importButtonTitle')}
            >
              <CrossIcon height="1rem" width="1rem" />
              <p>
                {t(`musicManagement:playlists.playlistSelection.deselect`, {
                  amount: selectedSongs.length,
                })}
              </p>
            </IconWrapperWithInfo>
            <RightSongSelect>
              {isPlaylist ? (
                <>
                  <Divider />
                  {loadingSavePlaylist ? (
                    <SmallLoadingIndicator color="primary" size="smallMedium" />
                  ) : (
                    <IconWrapper
                      onClick={handleDeleteSelectedSongs}
                      title={t('integrations:spotify.import.importButtonTitle')}
                      deleteHover
                    >
                      <TrashIcon
                        height="1.8rem"
                        width="1.8rem"
                        color={theme.waterBlue}
                      />
                    </IconWrapper>
                  )}
                </>
              ) : null}
              <Divider />
              <IconWrapper
                onClick={toggleAddToListPopup}
                title={t('integrations:spotify.import.importButtonTitle')}
              >
                <AddToListIcon
                  height="1.7rem"
                  width="1.7rem"
                  color={theme.waterBlue}
                />
              </IconWrapper>
            </RightSongSelect>
          </SongSelectSection>
        ) : (
          <>
            {!isNarrowerThanLarge && songs.length > 0 ? (
              <SubHeader>
                <Divider />
                <IconWrapper onClick={openSongInfoSelectionModal}>
                  <SongInfoIcon
                    height="1.7rem"
                    width="1.7rem"
                    color={theme.dark300}
                  />
                </IconWrapper>
                <Divider />
                {selectedPlaylist?.id ? (
                  <>
                    <IconWrapper onClick={handleSortSongs}>
                      <SortIcon
                        height="1.7rem"
                        width="1.7rem"
                        color={theme.dark300}
                      />
                    </IconWrapper>
                    <Divider />
                  </>
                ) : null}
                <IconWrapper onClick={toggleAddToListPopup}>
                  <AddToListIcon
                    height="1.7rem"
                    width="1.7rem"
                    color={theme.dark300}
                  />
                </IconWrapper>
              </SubHeader>
            ) : null}
          </>
        )}
      </HeaderWrapper>
      {isNarrowerThanLarge && !isSongSelectionActivated ? (
        <MobileSubHeader>
          {isNarrowerThanMedium &&
          selectedPlaylistType === PlaylistTypes.SEARCH ? (
            <PlaylistTitle
              $isSearch={selectedPlaylistType === PlaylistTypes.SEARCH}
              $isMobile
            >
              {selectedPlaylist?.title ? (
                <>
                  {typeof selectedPlaylist.title === 'string'
                    ? capitalize(selectedPlaylist.title)
                    : selectedPlaylist.title}
                </>
              ) : (
                ''
              )}
            </PlaylistTitle>
          ) : (
            <SongAmount>
              {t('musicManagement:playlists.list.header.songs', {
                amount: songs.length,
              })}
            </SongAmount>
          )}
          <SubHeader>
            {songs.length > 0 ? (
              <>
                <IconWrapper onClick={openSongInfoSelectionModal}>
                  <SongInfoIcon
                    height="1.7rem"
                    width="1.7rem"
                    color={theme.dark300}
                  />
                </IconWrapper>
                <Divider />
              </>
            ) : null}
            {selectedPlaylist?.id ? (
              <>
                <IconWrapper onClick={handleSortSongs}>
                  <SortIcon
                    height="1.7rem"
                    width="1.7rem"
                    color={theme.dark300}
                  />
                </IconWrapper>
                <Divider />
              </>
            ) : null}
            {songs.length > 0 ? (
              <IconWrapper onClick={toggleAddToListPopup}>
                <AddToListIcon
                  height="1.7rem"
                  width="1.7rem"
                  color={theme.dark300}
                />
              </IconWrapper>
            ) : null}
          </SubHeader>
        </MobileSubHeader>
      ) : null}
      {errorMessage ? <StyledErrorMessage error={errorMessage} /> : null}
      {loading || loadingSongsOfMusicCollection || loadingSearchSongs ? (
        <StyledLoadingIndicator color="primary" size="medium" />
      ) : (
        <SortableContext
          items={rowVirtualizer.virtualItems.map(
            (virtualRow) => sortedSongs[virtualRow.index]
          )}
          strategy={verticalListSortingStrategy}
        >
          <ScrollContainer
            ref={parentRef}
            $extraHeight={isSongSelectionActivated && isNarrowerThanLarge}
          >
            <VirtualRow $totalSize={rowVirtualizer.totalSize}>
              {rowVirtualizer.virtualItems.length > 0 ? (
                rowVirtualizer.virtualItems.map((virtualRow) => (
                  <ItemMeasurer
                    key={sortedSongs[virtualRow.index].id}
                    Component={SongWrapper}
                    measure={virtualRow.measureRef}
                    start={virtualRow.start}
                  >
                    <DraggableSong
                      id={sortedSongs[virtualRow.index].id}
                      index={virtualRow.index}
                      selectedPlaylistType={selectedPlaylistType}
                      setTab={setTab}
                      song={sortedSongs[virtualRow.index]}
                      sortBy={sortBy}
                    />
                  </ItemMeasurer>
                ))
              ) : (
                <Info>{t('musicManagement:playlists.noSongs')}</Info>
              )}
            </VirtualRow>
          </ScrollContainer>
        </SortableContext>
      )}
      {createPortal(
        <DragOverlay dropAnimation={null}>
          {activeItem ? <Song clone song={activeItem} /> : null}
        </DragOverlay>,
        document.body
      )}
      <DeleteModal
        isOpen={deletePlaylistModalIsOpen}
        onCancel={toggleDeletePlaylistModalIsOpen}
        onConfirm={handleDeletePlaylist}
        requestError={deletePlaylistError}
        requestLoading={deletePlaylistLoading}
        deleteMessage={t('musicManagement:playlists.list.delete.text')}
      />
      <PlaylistModal
        isOpen={!!modalMode}
        mode={modalMode}
        toggle={handleClosePlaylistModal}
      />
      <PlaylistSelectionModal
        instructions={t(
          'musicManagement:playlists.playlistSelection.instructions'
        )}
        isOpen={isAddToListPopUpOpen}
        onClose={handleClosePlaylistSelectionModal}
        songs={isSongSelectionActivated ? selectedSongs : songs}
        title={t('musicManagement:playlists.playlistSelection.title')}
      />
      <SongInfoSelectionModal
        isOpen={isSongInfoSelectionOpen}
        onClose={closeSongInfoSelectionModal}
      />
      <ZoneSelectionModal
        actionIsLoading={loadingCopyPlaylists}
        error={errorCopyPlaylists}
        instructions={t(
          'musicManagement:playlists.list.shareZonesInstructions'
        )}
        isMulti
        isOpen={zoneMultiSelectionModalIsOpen}
        onClose={handleCloseZones}
        onConfirm={handleConfirmSelection}
        title={t('musicManagement:musicReport.zoneSelectionModaltitle')}
        noZonesMessage={t(
          'musicManagement:playlists.playlistSelection.noZonesMessage'
        )}
        zones={partialZones}
      />
      {areSettingsOpen ? (
        <CollectionSettingsModal
          data={dataCollectionSettings}
          error={errorCollectionSettings}
          isOpen={areSettingsOpen}
          loading={loadingCollectionSettings}
          onClose={handleCloseSettings}
          selectedPlaylist={selectedPlaylist}
          title={t('musicManagement:playlists.collectionSettings.title')}
          zone={selectedZone}
        />
      ) : null}
    </>
  );
};

export default PlaylistDetail;
