import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useSessionStorage } from '../../../common';
import {
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  SUPPORT_ADMIN_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../../global/auth';
import { useAuth } from '../../../global/auth/newAuthProvider';
import {
  ADD_ITEMS_TO_CART,
  GET_AVAILABLE_ADDONS,
  GET_AVAILABLE_PAID_ADDONS,
  GET_AVAILABLE_PAID_PRODUCTS,
  GET_AVAILABLE_PRODUCTS,
  GET_ORGANIZATION_DETAILS,
  GET_PAYMENT_OPTIONS,
  SWITCH_CART_ITEMS,
} from './api';
import { PurchaseContext } from './purchaseContext';
import {
  PurchaseAction,
  initialPurchaseState,
  purchaseReducer,
} from './purchaseReducer';
import { buildCartItems } from './utils';

const PurchaseProvider = ({ children }) => {
  const { organizationId, locationId, zoneId, currentStep } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [impersonationId] = useSessionStorage('impersonationId');
  const isSupportAdmin = hasAllRequiredPermissions(
    user,
    SUPPORT_ADMIN_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    user,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );

  const [state, dispatch] = useReducer(purchaseReducer, initialPurchaseState);

  useEffect(() => {
    if ((isSupportAdmin || isPartnerManager) && impersonationId) {
      if (currentStep > 1 && !state.userInput.selectedMusicLicense?.id) {
        // User navigated directly to a step through the URL without completing the necessary steps
        navigate(
          `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/0`,
          {
            replace: true,
          }
        );
      }
    } else if (currentStep !== 0 && !state.userInput.selectedMusicLicense?.id) {
      // User navigated directly to a step through the URL without completing the necessary steps
      navigate(
        `/organizations/${organizationId}/locations/${locationId}/zones/${zoneId}/purchase/0`,
        {
          replace: true,
        }
      );
    }
  }, [
    currentStep,
    navigate,
    locationId,
    organizationId,
    zoneId,
    state.userInput.selectedMusicLicense,
    isPartnerManager,
    isSupportAdmin,
    impersonationId,
  ]);

  const {
    loading: initialQueryLoading,
    error: initialQueryError,
    data: initialQueryData,
  } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: { organizationId, locationId, zoneId },
    notifyOnNetworkStatusChange: true,
    // This is necessary because otherwise Apollo will "remember" the previous results
    fetchPolicy: 'network-only',
    onCompleted: (dataResult) => {
      dispatch({
        type: PurchaseAction.FETCHED_ORGANIZATION_DETAILS,
        payload: {
          organizationDetails: dataResult.organization,
          currentZoneId: zoneId,
        },
      });
    },
  });

  // Addon catalogue for regular customers
  const [
    getAvailablePaidAddons,
    { loading: availablePaidAddonsLoading, error: availablePaidAddonsError },
  ] = useLazyQuery(GET_AVAILABLE_PAID_ADDONS, {
    variables: { organizationId },
    onCompleted: (data) => {
      dispatch({
        type: PurchaseAction.FETCHED_ADD_ONS,
        payload: { availableAddons: data.availablePaidAddons },
      });
    },
  });

  // Addon catalogue for support (this allows to choose from free addons)
  const { loading: availableAddonsLoading, error: availableAddonsError } =
    useQuery(GET_AVAILABLE_ADDONS, {
      skip: !((isSupportAdmin || isPartnerManager) && impersonationId),
      variables: {
        organizationId,
        productCatalogue: state.selectedProductCatalogue?.id,
      },
      onCompleted: (data) => {
        if (data) {
          // The onCompleted callback is executed before the query even fires,
          // must be an Apollo bug...
          dispatch({
            type: PurchaseAction.FETCHED_ADD_ONS,
            payload: { availableAddons: data.availableAddons },
          });
        }
      },
    });

  // Product catalogue for regular customers
  const [
    getAvailablePaidProducts,
    {
      loading: availablePaidProductsLoading,
      error: availablePaidProductsError,
    },
  ] = useLazyQuery(GET_AVAILABLE_PAID_PRODUCTS, {
    variables: { organizationId },
    onCompleted: (data) => {
      dispatch({
        type: PurchaseAction.FETCHED_PRODUCT_CATALOGUE,
        payload: { productCatalogue: data.organization.availablePaidProducts },
      });
    },
  });

  // Product catalogue for support (this allows to choose from free products)
  const { loading: availableProductsLoading, error: availableProductsError } =
    useQuery(GET_AVAILABLE_PRODUCTS, {
      skip: !((isSupportAdmin || isPartnerManager) && impersonationId),
      variables: {
        organizationId,
        productCatalogue: state.selectedProductCatalogue?.id,
      },
      onCompleted: (data) => {
        if (data) {
          // The onCompleted callback is executed before the query even fires,
          // must be an Apollo bug...
          dispatch({
            type: PurchaseAction.FETCHED_PRODUCT_CATALOGUE,
            payload: { productCatalogue: data.availableProducts },
          });
        }
      },
    });

  const [
    addItemsToCart,
    { loading: addItemsToCartLoading, error: addItemsToCartError },
  ] = useMutation(ADD_ITEMS_TO_CART, {
    onCompleted: (data) => {
      dispatch({
        type: PurchaseAction.FETCHED_SALE_ORDER,
        payload: { saleOrder: data.addItemsToCart },
      });
    },
  });

  const [
    switchCartItems,
    { loading: switchCartItemsLoading, error: switchCartItemsError },
  ] = useMutation(SWITCH_CART_ITEMS, {
    onCompleted: (data) => {
      dispatch({
        type: PurchaseAction.FETCHED_SALE_ORDER,
        payload: { saleOrder: data.addItemsToCart },
      });
    },
  });

  const { loading: paymentOptionsLoading, error: paymentOptionsError } =
    useQuery(GET_PAYMENT_OPTIONS, {
      skip: !state.selectedProduct,
      variables: {
        countryId: state.organizationDetails?.countryId,
        productIds: [state.selectedProduct?.id],
      },
      onCompleted: (data) => {
        if (data) {
          // The onCompleted callback is executed before the query even fires,
          // must be an Apollo bug...
          dispatch({
            type: PurchaseAction.FETCHED_PAYMENT_OPTIONS,
            payload: { paymentOptions: data.paymentOptions },
          });
        }
      },
    });

  useEffect(() => {
    if (!((isSupportAdmin || isPartnerManager) && impersonationId)) {
      // When the viewer has not the support role,
      // or the viewer is not impersonating someone,
      // always fetch the regular, paid product and addon catalogue.
      getAvailablePaidProducts();
      getAvailablePaidAddons();
    }
  }, [
    getAvailablePaidProducts,
    isSupportAdmin,
    impersonationId,
    isPartnerManager,
    getAvailablePaidAddons,
  ]);

  const buildSaleOrder = useCallback(
    (skipAddonFiltering) => {
      if (state.saleOrderNeedsToBeFetched) {
        const currentZone = initialQueryData.zone;
        const totalSelectedZones = [
          ...state.userInput.selectedAdditionalZones,
          currentZone,
        ];

        if (!state.saleOrder) {
          addItemsToCart({
            variables: {
              organizationId,
              cartItems: buildCartItems(
                totalSelectedZones,
                state.selectedProduct,
                state.userInput.selectedAddons,
                state.availableAddons,
                skipAddonFiltering
              ),
            },
          });
        } else {
          switchCartItems({
            variables: {
              cartId: state.saleOrder.id,
              organizationId,
              cartItemIdsToRemove: state.saleOrder.saleOrderLines.map(
                (saleOrderLine) => saleOrderLine.cartLineId
              ),
              cartItemsToAdd: buildCartItems(
                totalSelectedZones,
                state.selectedProduct,
                state.userInput.selectedAddons,
                state.availableAddons,
                skipAddonFiltering
              ),
            },
          });
        }
      }
    },
    [
      state.selectedProduct,
      state.saleOrderNeedsToBeFetched,
      state.saleOrder,
      state.availableAddons,
      state.userInput.selectedAddons,
      addItemsToCart,
      organizationId,
      state.userInput.selectedAdditionalZones,
      switchCartItems,
      initialQueryData,
    ]
  );

  const onSelectProductCatalogue = useCallback((productCatalogue) => {
    dispatch({
      type: PurchaseAction.SELECT_PRODUCT_CATALOGUE,
      payload: { productCatalogue },
    });
  }, []);

  const onSelectMusicLicense = useCallback((musicLicense) => {
    dispatch({
      type: PurchaseAction.SELECT_MUSIC_LICENSE,
      payload: { musicLicense },
    });
  }, []);

  const onSelectColor = useCallback((color) => {
    dispatch({ type: PurchaseAction.SELECT_COLOR, payload: { color } });
  }, []);

  const onSelectSubscriptionTemplate = useCallback((subscriptionTemplate) => {
    dispatch({
      type: PurchaseAction.SELECT_SUBSCRIPTION_TEMPLATE,
      payload: { subscriptionTemplate },
    });
  }, []);

  const onSelectAdditionalZone = useCallback(
    (additionalZone) => {
      if (
        state.userInput.selectedAdditionalZones.some(
          (az) => az.id === additionalZone.id
        )
      ) {
        dispatch({
          type: PurchaseAction.DESELECT_ADDITIONAL_ZONE,
          payload: { additionalZone },
        });
      } else {
        dispatch({
          type: PurchaseAction.SELECT_ADDITIONAL_ZONE,
          payload: { additionalZone },
        });
      }
    },
    [state]
  );

  const updateOrganizationDetails = useCallback((organizationDetails) => {
    dispatch({
      type: PurchaseAction.EDIT_ORGANIZATION_DETAILS,
      payload: { organizationDetails },
    });
  }, []);

  const onSelectPaymentOption = useCallback((paymentOption) => {
    dispatch({
      type: PurchaseAction.SELECT_PAYMENT_OPTION,
      payload: { paymentOption },
    });
  }, []);

  const onToggleForeverSwitch = useCallback((wantsForeverProduct) => {
    dispatch({
      type: PurchaseAction.TOGGLE_FOREVER_PRODUCT_SWITCH,
      payload: { wantsForeverProduct },
    });
  }, []);

  const handleSelectAddon = useCallback(
    (addon) => {
      if (
        state.userInput.selectedAddons.find(
          (selectedAddon) => selectedAddon.id === addon.id
        )
      ) {
        dispatch({
          type: PurchaseAction.DESELECT_ADDON,
          payload: { addon },
        });
      } else {
        dispatch({
          type: PurchaseAction.SELECT_ADDON,
          payload: { addon },
        });
      }
    },
    [state.userInput.selectedAddons]
  );

  const contextValue = useMemo(
    () => ({
      organizationDetails: state.organizationDetails || {},
      userInput: state.userInput,
      availableAdditionalZones: state.availableAdditionalZones,
      availableMusicLicenses: state.availableMusicLicenses,
      availableColors: state.availableColors,
      availableSubscriptionTemplates: state.availableSubscriptionTemplates,
      selectedAdditionalZones: state.userInput.selectedAdditionalZones,
      selectedProduct: state.selectedProduct,
      handleSelectAdditionalZone: onSelectAdditionalZone,
      handleSelectMusicLicense: onSelectMusicLicense,
      handleSelectColor: onSelectColor,
      handleSelectSubscriptionTemplate: onSelectSubscriptionTemplate,
      handleSelectPaymentOption: onSelectPaymentOption,
      handleToggleForeverSwitch: onToggleForeverSwitch,
      countries: initialQueryData?.countries || [],
      vatCountryCodes: initialQueryData?.vatCountryCodes || [],
      updateOrganizationDetails,
      saleOrderLoading: addItemsToCartLoading || switchCartItemsLoading,
      saleOrderError: addItemsToCartError || switchCartItemsError,
      paymentOptionsLoading,
      paymentOptionsError,
      saleOrder: state.saleOrder,
      productCatalogue: state.productCatalogue,
      availablePaymentOptions: state.availablePaymentOptions,
      currentLocation: initialQueryData?.location || {},
      currentZone: initialQueryData?.zone || {},
      buildSaleOrder,
      handleSelectProductCatalogue: onSelectProductCatalogue,
      selectedProductCatalogue: state.selectedProductCatalogue,
      productCatalogueLoading: availableProductsLoading,
      productCatalogueError: availableProductsError,
      availableAddons: state.availableAddons,
      addonsLoading: availablePaidAddonsLoading || availableAddonsLoading,
      addonsError: availablePaidAddonsError || availableAddonsError,
      handleSelectAddon,
      isLoading: initialQueryLoading || availablePaidProductsLoading,
      hasError: initialQueryError || availablePaidProductsError,
    }),
    [
      state,
      onSelectAdditionalZone,
      onSelectMusicLicense,
      onSelectColor,
      onSelectSubscriptionTemplate,
      onSelectPaymentOption,
      onToggleForeverSwitch,
      initialQueryData,
      updateOrganizationDetails,
      addItemsToCartLoading,
      addItemsToCartError,
      paymentOptionsLoading,
      paymentOptionsError,
      switchCartItemsLoading,
      switchCartItemsError,
      buildSaleOrder,
      onSelectProductCatalogue,
      availableProductsError,
      availableProductsLoading,
      availablePaidAddonsLoading,
      availablePaidAddonsError,
      handleSelectAddon,
      availableAddonsLoading,
      availableAddonsError,
      initialQueryLoading,
      availablePaidProductsLoading,
      initialQueryError,
      availablePaidProductsError,
    ]
  );

  return (
    <PurchaseContext.Provider value={contextValue}>
      {children}
    </PurchaseContext.Provider>
  );
};

export default PurchaseProvider;
