import { forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';

const Page = forwardRef(({ title, children, className }, ref) => {
  return (
    <>
      <Helmet>
        <title>{title} | MyTunify</title>
      </Helmet>
      <div ref={ref} className={className}>
        {children}
      </div>
    </>
  );
});

export default Page;
