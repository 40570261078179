import Cookies from 'js-cookie';

import config from '../../config';

const { LANGUAGE_COOKIE_KEY, IPSTACK_API_KEY, IPSTACK_BASE_URL } = config;

export const fetchCountryInfoFromIpService = async () => {
  const response = await window.fetch(
    `${IPSTACK_BASE_URL}/check?access_key=${IPSTACK_API_KEY}&fields=country_code,ip`
  );

  if (!response.ok) {
    throw new Error('Request country code from ip service error');
  }

  const data = await response.json();

  if (!data.country_code) {
    throw new Error('Request country code from ip service error');
  }

  return data;
};

export const predictViewerCountryCode = async () => {
  const lang = Cookies.get(LANGUAGE_COOKIE_KEY);
  const [, langCountryCode] = lang.split('-');

  if (langCountryCode.toLowerCase() !== 'us') {
    return langCountryCode; // We predict the viewer country code based on the selected language
  }

  try {
    const { country_code } = await fetchCountryInfoFromIpService(); // We try to predict the view country code based on IP

    return country_code;
  } catch {
    return null; // We cannot predict the viewer country code
  }
};
