import styled from 'styled-components';
import { ReactComponent as CheckmarkIcon } from '../../assets/icons/smallCheckmark.svg';
import { ReactComponent as CrossIcon } from '../../assets/icons/close.svg';
import theme from '../../global/style/theme';

export const Center = styled.div`
  width: 100%;
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
  display: grid;
  justify-items: center;
  padding: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 3.2rem;
  }
`;

export const MaxWidth = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.white};
  padding: 6.4rem 1.6rem;
  border: 1px solid ${({ theme }) => theme.stroke};

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.small}px) {
    padding: 6.4rem 4rem;
  }
`;

export const Paper = styled.div`
  display: grid;
  padding-bottom: 3.2rem;
  text-align: center;
`;

export const Icon = styled.div`
  justify-self: center;
  width: 10rem;
  height: 10rem;
  border: 2px solid
    ${({ isSuccess, theme }) =>
      isSuccess ? theme.plantGreen : theme.grapefruitRed};
  border-radius: 50%;
  margin-bottom: 3rem;
  display: grid;
  align-items: center;
  justify-content: center;
`;

export const Checkmark = () => (
  <CheckmarkIcon width="3rem" height="3rem" color={theme.plantGreen} />
);

export const Cross = () => (
  <CrossIcon width="3rem" height="3rem" color={theme.grapefruitRed} />
);

export const Title = styled.h1`
  font-size: 2.4rem;
  padding-bottom: 2rem;
  font-weight: 600;
`;

export const Description = styled.p`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.dark400};
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  justify-items: center;
`;
