import * as Yup from 'yup';
import { areIntervalsOverlapping, getHours, getMinutes, set } from 'date-fns';

export const openingHoursValidationSchema = (t) =>
  Yup.object().shape({
    googleSynced: Yup.boolean().required(),
    openingDays: Yup.array()
      .of(
        Yup.object().shape({
          openingPeriods: Yup.array()
            .of(
              Yup.object()
                .test(
                  'isvalidTimeRange',
                  t('common:form.validation.time.after'),
                  (value) => {
                    if (value.open && value.close) {
                      const openDate =
                        value.open instanceof Date
                          ? value.open
                          : set(new Date(), {
                              hours: Number(value.open.slice(0, 2)),
                              minutes: Number(value.open.slice(3, 5)),
                              seconds: 0,
                            });
                      const closeDate =
                        value.close instanceof Date
                          ? value.close
                          : set(new Date(), {
                              hours: Number(value.close.slice(0, 2)),
                              minutes: Number(value.close.slice(3, 5)),
                              seconds: 0,
                            });
                      const openHours = getHours(openDate);
                      const openMinutes = getMinutes(openDate);
                      const closeHours = getHours(closeDate);
                      const closeMinutes = getMinutes(closeDate);
                      if (
                        openHours === 0 &&
                        openMinutes === 0 &&
                        closeHours === 0 &&
                        closeMinutes === 0
                      ) {
                        return true;
                      }
                      return openDate < closeDate;
                    }
                    return true;
                  }
                )
                .test(
                  'hasBothFilledInOrBothNull',
                  t('common:form.validation.time.both'),
                  (value) => {
                    return (
                      value.open === null ||
                      value.close === null ||
                      value.open ||
                      value.close
                    );
                  }
                )
            )
            // .test(
            //   'hasNoMixedRows',
            //   t('common:form.validation.time.mixed'),
            //   (value) => {
            //     const amountOfNullRows = value.reduce((acc, curr) => {
            //       if (curr.open === null && curr.close === null) {
            //         return acc + 1;
            //       }
            //       return acc;
            //     }, 0);
            //     const amountOfFilledRows = value.reduce((acc, curr) => {
            //       if (curr.open && curr.close) {
            //         return acc + 1;
            //       }
            //       return acc;
            //     }, 0);
            //     return amountOfFilledRows > 0 ? amountOfNullRows === 0 : true;
            //   }
            // )
            .test(
              'hasOnlyOneNullRow',
              t('common:form.validation.time.multipleEmpty'),
              (value) => {
                const amountOfNullRows = value.reduce((acc, curr) => {
                  if (curr.open === null && curr.close === null) {
                    return acc + 1;
                  }
                  return acc;
                }, 0);
                return amountOfNullRows <= 1;
              }
            )
            .test(
              'hasNoOverlappingTimes',
              t('common:form.validation.time.overlapping'),
              (value) => {
                // We test every period with its successors
                for (let i = 0; i < value.length; i += 1) {
                  if (value[i].open && value[i].close) {
                    for (let j = i + 1; j < value.length; j += 1) {
                      if (value[j].open && value[j].close) {
                        const firstOpenDate =
                          value[i].open instanceof Date
                            ? value[i].open
                            : set(new Date(), {
                                hours: Number(value[i].open.slice(0, 2)),
                                minutes: Number(value[i].open.slice(3, 5)),
                                seconds: 0,
                              });
                        const firstCloseDate =
                          value[i].close instanceof Date
                            ? value[i].close
                            : set(new Date(), {
                                hours: Number(value[i].close.slice(0, 2)),
                                minutes: Number(value[i].close.slice(3, 5)),
                                seconds: 0,
                              });
                        const secondOpenDate =
                          value[j].open instanceof Date
                            ? value[j].open
                            : set(new Date(), {
                                hours: Number(value[j].open.slice(0, 2)),
                                minutes: Number(value[j].open.slice(3, 5)),
                                seconds: 0,
                              });
                        const secondCloseDate =
                          value[j].close instanceof Date
                            ? value[j].close
                            : set(new Date(), {
                                hours: Number(value[j].close.slice(0, 2)),
                                minutes: Number(value[j].close.slice(3, 5)),
                                seconds: 0,
                              });
                        if (
                          firstOpenDate < firstCloseDate &&
                          secondOpenDate < secondCloseDate &&
                          areIntervalsOverlapping(
                            { start: firstOpenDate, end: firstCloseDate },
                            { start: secondOpenDate, end: secondCloseDate }
                          )
                        ) {
                          return false;
                        }
                      }
                    }
                  }
                }
                return true;
              }
            ),
        })
      )
      .required(),
  });
