import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';

import { useToggle, useSortByObjectField } from '../../../common';
import DuplicateModal from './modals/duplicateModal';
import {
  GET_CUSTOM_PLAYLISTS_OF_ZONE,
  GET_FAVOURITE_CUSTOM_PLAYLIST_OF_ZONE,
  ADD_SONGS_TO_CUSTOM_PLAYLIST,
  GET_MUSIC_CHANNEL_GROUPS,
  SEARCH_SONGS_BY_VALUE,
  AUTOCOMPLETION_SONGS_AND_ARTISTS,
} from './api';
import { useUpdatePlaylistCacheAfterAddSongs } from './cache';
import { PlaylistTypes } from './types';
import SilenceSound from '../../../assets/silence.mp3';

const playlistsReducer = (state, payload) => {
  return {
    ...state,
    ...payload,
  };
};

const initialPlaylistsState = {
  searchValue: '',
  tempSearchValue: '',
  selectedPlaylist: null,
  overId: null,
  activeId: null,
  chosenPlaylistId: null,
  chosenSongIds: [],
  modalMode: null,
  duplicatesAmount: 0,
  selectedPlaylistType: PlaylistTypes.CUSTOM,
  selectedTunifyList: null,
  songs: [],
  searchSongsIsSuggestion: false,
  searchSongsType: '',
  searchSongsSongId: null,
  lastSongValue: 'year',
  selectedSongs: [],
  isSongSelectionActivated: false,
  currentSongPlaying: null,
  changeableParameters: [],
};

const PlaylistsContext = createContext();

export const PlaylistsManager = ({ children, zones }) => {
  const { t, i18n } = useTranslation();

  const audioRef = useRef(null);

  const [duplicateSongsModalIsOpen, toggleDuplicateSongsModal] = useToggle();

  const [state, dispatch] = useReducer(playlistsReducer, {
    ...initialPlaylistsState,
    selectedZone: zones[0],
  });

  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [audioSrc, setAudioSrc] = useState(null);

  const {
    data: dataCustomPlaylists,
    loading: loadingCustomPlaylists,
    error: errorCustomPlaylists,
    refetch: refetchCustomPlaylists,
  } = useQuery(GET_CUSTOM_PLAYLISTS_OF_ZONE, {
    variables: {
      zoneId: state.selectedZone.id,
    },
  });

  const customPlaylists = useSortByObjectField(
    dataCustomPlaylists?.customPlaylistsOfZone?.customPlaylists,
    'title'
  );

  const { data: dataMusicChannelGroups, loading: loadingMusicChannelGroups } =
    useQuery(GET_MUSIC_CHANNEL_GROUPS, {
      variables: {
        zoneId: state.selectedZone.id,
        language: i18n.language,
      },
    });

  const {
    loading: loadingSearchSongs,
    error: errorSearchSongs,
    refetch: refetchSearchSongs,
  } = useQuery(SEARCH_SONGS_BY_VALUE, {
    variables: {
      zoneId: state.selectedZone.id,
      isSuggestion: state.searchSongsIsSuggestion,
      type: state?.searchSongsType,
      songId: state?.searchSongsSongId,
      value: state?.searchValue,
    },
    skip:
      (!state.searchSongsType && !state?.searchValue) ||
      (state.searchSongsIsSuggestion &&
        !state?.searchSongsType &&
        !state?.searchSongsSongId),
    onCompleted: (data) => {
      if (data?.searchSongsByValue) {
        dispatch({
          songs: data.searchSongsByValue.map((song, index) => ({
            ...song,
            id: `${song.id}${index}`,
            songId: song.id,
          })),
        });
      }
    },
  });

  const { data: dataSongsAndArtists } = useQuery(
    AUTOCOMPLETION_SONGS_AND_ARTISTS,
    {
      variables: {
        value: state?.tempSearchValue,
      },
      skip: !state?.tempSearchValue,
    }
  );

  const { data: dataFavouritePlaylist, loading: loadingFavouritePlaylist } =
    useQuery(GET_FAVOURITE_CUSTOM_PLAYLIST_OF_ZONE, {
      variables: {
        zoneId: state.selectedZone.id,
      },
    });

  const handleUpdatePlaylistCacheAfterAddSongs =
    useUpdatePlaylistCacheAfterAddSongs(
      {
        zoneId: state.selectedZone.id,
        playlistId: state.chosenPlaylistId,
        selectedPlaylistId: state.selectedPlaylist?.id,
      },
      (playlist) => {
        dispatch({
          selectedPlaylist: playlist,
        });
      }
    );

  const [
    addSongsToCustomPlaylist,
    { loading: loadingAddSongs, error: errorAddSongs },
  ] = useMutation(ADD_SONGS_TO_CUSTOM_PLAYLIST, {
    refetchQueries: ['getSongsOfPlaylist'],
    update: handleUpdatePlaylistCacheAfterAddSongs,
    onCompleted: (d) => {
      if (d?.addSongsToCustomPlaylist?.updateInfos) {
        refetchCustomPlaylists();
      }
      if (d?.addSongsToCustomPlaylist?.success) {
        if (duplicateSongsModalIsOpen) {
          toggleDuplicateSongsModal();
        }
      } else if (
        !d?.addSongsToCustomPlaylist?.success &&
        d.addSongsToCustomPlaylist?.duplicatesAmount
      ) {
        dispatch({
          duplicatesAmount: d.addSongsToCustomPlaylist.duplicatesAmount,
        });
        toggleDuplicateSongsModal();
      }
    },
  });

  const handleEndedAudio = useCallback(() => {
    audioRef.current.currentTime = 0;
    setProgress(audioRef.current.currentTime);
    setIsPlaying(false);
  }, []);

  const handleTimeShift = useCallback((newTime) => {
    if (audioRef?.current?.duration) {
      if (audioRef.current.paused) {
        audioRef.current.currentTime = newTime;
        setProgress(audioRef.current.currentTime);
      } else {
        audioRef.current.pause();
        audioRef.current.currentTime = newTime;
        setProgress(audioRef.current.currentTime);
        audioRef.current.play();
      }
    }
  }, []);

  const startStopAudio = useCallback(() => {
    if (audioSrc) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        setIsPlaying(true);
      } else {
        audioRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [audioSrc, audioRef]);

  const changeSliderAudio = useCallback(
    (value) => {
      handleTimeShift(audioRef.current.duration * value);
    },
    [handleTimeShift]
  );

  const fastForwardAudio = useCallback(() => {
    handleTimeShift(audioRef.current.currentTime + 10);
  }, [handleTimeShift]);

  const windBackAudio = useCallback(() => {
    handleTimeShift(audioRef.current.currentTime - 10);
  }, [handleTimeShift]);

  const stopAudio = useCallback(() => {
    if (audioRef?.current) {
      if (!audioRef.current.paused) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setProgress(audioRef.current.currentTime);
        setIsPlaying(false);
      }
      setAudioSrc(null);
      setProgress(0);
    }
    dispatch({
      currentSongPlaying: null,
    });
  }, []);

  const setProgressToCurrentTime = useCallback(() => {
    setProgress(audioRef.current.currentTime);
    setDuration(audioRef.current.duration);
  }, []);

  const value = useMemo(
    () => ({
      ...state,
      dispatch,
      zones,
      customPlaylists,
      customPlaylistsTimestamp:
        dataCustomPlaylists?.customPlaylistsOfZone?.timestamp,
      favouritePlaylist: dataFavouritePlaylist?.favouriteCustomPlaylistOfZone,
      tunifyLists: dataMusicChannelGroups?.musicChannelGroups,
      loadingTunifyLists: loadingMusicChannelGroups,
      loadingCustomPlaylists,
      errorCustomPlaylists,
      loadingFavouritePlaylist,
      loadingSearchSongs,
      loadingAddSongs,
      errorSearchSongs,
      addSongsToCustomPlaylist,
      songsAndArtists: dataSongsAndArtists?.autocompletionSongsAndArtists,
      refetchSearchSongs,
      isPlaying,
      progress,
      startStopAudio,
      changeSliderAudio,
      fastForwardAudio,
      windBackAudio,
      setProgressToCurrentTime,
      stopAudio,
      setAudioSrc,
      duration,
      setIsPlaying,
      refetchCustomPlaylists,
    }),
    [
      state,
      zones,
      loadingCustomPlaylists,
      customPlaylists,
      dataCustomPlaylists?.customPlaylistsOfZone?.timestamp,
      dataFavouritePlaylist?.favouriteCustomPlaylistOfZone,
      loadingFavouritePlaylist,
      errorCustomPlaylists,
      loadingSearchSongs,
      loadingAddSongs,
      addSongsToCustomPlaylist,
      dataMusicChannelGroups?.musicChannelGroups,
      loadingMusicChannelGroups,
      errorSearchSongs,
      dataSongsAndArtists,
      refetchSearchSongs,
      isPlaying,
      progress,
      startStopAudio,
      changeSliderAudio,
      fastForwardAudio,
      windBackAudio,
      stopAudio,
      setProgressToCurrentTime,
      setAudioSrc,
      duration,
      setIsPlaying,
      refetchCustomPlaylists,
    ]
  );

  const handleAddSongs = useCallback(
    (response) => {
      const playlists = [
        ...customPlaylists,
        dataFavouritePlaylist?.favouriteCustomPlaylistOfZone,
      ].filter(Boolean);
      const currentPlaylistTimestamp = playlists.find(
        (playlist) => Number(playlist.id) === Number(state?.chosenPlaylistId)
      )?.timestamp;
      if (currentPlaylistTimestamp) {
        addSongsToCustomPlaylist({
          variables: {
            zoneId: state.selectedZone.id,
            playlistId: state?.chosenPlaylistId,
            timestamp: currentPlaylistTimestamp,
            songIds: state.chosenSongIds,
            duplicateHandling: response,
          },
        });
      }
    },
    [
      state,
      addSongsToCustomPlaylist,
      customPlaylists,
      dataFavouritePlaylist?.favouriteCustomPlaylistOfZone,
    ]
  );

  return (
    <PlaylistsContext.Provider value={value}>
      {children}
      <DuplicateModal
        description={t('musicManagement:playlists.list.duplicate.text', {
          duplicatesAmount: state.duplicatesAmount,
        })}
        isOpen={duplicateSongsModalIsOpen}
        onCancel={toggleDuplicateSongsModal}
        onConfirm={handleAddSongs}
        requestError={errorAddSongs}
        requestLoading={loadingAddSongs}
        title={t('musicManagement:playlists.list.duplicate.title')}
      />
      <iframe
        style={{ display: 'none' }}
        src={SilenceSound}
        allow="autoplay"
        title="audioSong"
        id="audio"
      />
      {audioSrc && (
        <audio ref={audioRef} autoPlay onEnded={handleEndedAudio}>
          <source src={audioSrc} type="audio/mp3" />
          <source src={audioSrc} type="audio/mpeg" />
          <source src={audioSrc} type="audio/aac" />
          <source src={audioSrc} type="audio/ogg" />
          <source src={audioSrc} type="audio/wav" />
          <source src={audioSrc} type="audio/x-m4a" />
        </audio>
      )}
    </PlaylistsContext.Provider>
  );
};

export const usePlaylists = () => {
  return useContext(PlaylistsContext);
};
