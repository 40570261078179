export const determineAvailableSubscriptionTemplates = (
  productCatalogue,
  selectedMusicLicenseId,
  selectedColorId,
  userWantsForeverProduct
) => {
  const availableProducts = productCatalogue.filter((product) => product.isForever === userWantsForeverProduct);

  const productsWithSelectedMusicLicenseId = availableProducts.filter(
    (product) => product.musicLicense.id === selectedMusicLicenseId
  );

  const productsWithSelectedColorId = productsWithSelectedMusicLicenseId.filter(
    (product) => product.color.id === selectedColorId
  );

  const availableSubscriptionTemplatesById = productsWithSelectedColorId.reduce((acc, curr) => {
    if (!acc[curr.subscriptionTemplate.id]) {
      return { ...acc, [curr.subscriptionTemplate.id]: curr.subscriptionTemplate };
    }
    return acc;
  }, {});

  const availableSubscriptionTemplates = Object.keys(availableSubscriptionTemplatesById).map(
    (subscriptionTemplateId) => availableSubscriptionTemplatesById[subscriptionTemplateId]
  );

  return availableSubscriptionTemplates;
};
