import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  BasicButton,
  Button,
  capitalize,
  useMediaSize,
  useToggle,
} from '../../common';
import ExperimentalWrapper from './experimentalWrapper';
import MainNavItem from './mainNavItem';
import SubNavItem from './subNavItem';

import {
  ACCOUNTANT_ROLE_PERMISSIONS,
  EXPERIMENTAL_ROLE_PERMISSIONS,
  OWNER_ROLE_PERMISSIONS,
  PARTNER_MANAGER_ROLE_PERMISSIONS,
  PARTNER_ROLE_PERMISSIONS,
  SUPPORT_ROLE_PERMISSIONS,
  hasAllRequiredPermissions,
} from '../../global/auth';
import theme from '../../global/style/theme';
import CreateLeadModal from './createLeadModal';
import LogContactModal from './logContactModal';
import NotificationsPopup from './notificationsPopup';
import ScheduleCallModal from './scheduleCallModal';
import ShareImpersonationLink from './shareImpersonationLink';

import { useIntercom } from 'react-use-intercom';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import { ReactComponent as AlertActiveIcon } from '../../assets/icons/alertActive.svg';
import { ReactComponent as AlertActiveIcon2 } from '../../assets/icons/alertActive2.svg';
import { ReactComponent as ChatIcon } from '../../assets/icons/contact/chat.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/contact/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/contact/phone.svg';
import { ReactComponent as LogOutIcon } from '../../assets/icons/logOut.svg';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as AddonsIcon } from '../../assets/icons/navigation/addOn.svg';
import { ReactComponent as MusicReportIcon } from '../../assets/icons/navigation/analytics.svg';
import { ReactComponent as BroadcastIcon } from '../../assets/icons/navigation/audiospots.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/navigation/dashboard.svg';
import { ReactComponent as DashboardImpersonationIcon } from '../../assets/icons/navigation/dashboardImpersonation.svg';
import { ReactComponent as MusicManagementIcon } from '../../assets/icons/navigation/equalizer.svg';
import { ReactComponent as GiftsIcon } from '../../assets/icons/navigation/gifts.svg';
import { ReactComponent as GiftsImpersonationIcon } from '../../assets/icons/navigation/giftsImpersonation.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/navigation/home.svg';
import { ReactComponent as HomeImpersonationIcon } from '../../assets/icons/navigation/homeImpersonation.svg';
import { ReactComponent as InvoicesIcon } from '../../assets/icons/navigation/invoices.svg';
import { ReactComponent as HistoryIcon } from '../../assets/icons/navigation/logsHistory.svg';
import { ReactComponent as PartnerIcon } from '../../assets/icons/navigation/partner.svg';
import { ReactComponent as PartnerManagementIcon } from '../../assets/icons/navigation/partnerManagement.svg';
import { ReactComponent as ScheduledCallsIcon } from '../../assets/icons/navigation/plannedCalls.svg';
import { ReactComponent as MusicManagementPlaylistsIcon } from '../../assets/icons/navigation/playlists.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/navigation/searchUsers.svg';
import { ReactComponent as SubscriptionsIcon } from '../../assets/icons/navigation/subscription.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/profileWithGradient.svg';
import { ReactComponent as ProfileImpersonationIcon } from '../../assets/icons/profileWithGradientImpersonation.svg';
import { ReactComponent as RawPlayIcon } from '../../assets/icons/simplePlayControls/play.svg';
import config from '../../config';
import { useAuth } from '../../global/auth/newAuthProvider';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 7rem);
  overflow-y: auto;
  background-color: ${({ theme }) => theme.dark500};
  @supports (height: 100svh) {
    height: calc(100svh - 7rem);
  }
  position: absolute;
  bottom: 0;
  left: ${({ $isOpen }) => ($isOpen ? 0 : '-105vw')};
  transition: left 0.3s ease-in;
  z-index: 100;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    position: static;
    overflow-y: unset;
    height: ${({ $appVersion }) =>
      $appVersion ? 'calc(100vh - 2.5rem)' : '100vh'};
    @supports (height: 100svh) {
      height: 100svh;
    }
  }
`;

const Container = styled.aside`
  width: 100%;
  position: relative;
  background-color: ${({ theme }) => theme.dark500};
  display: grid;
  grid-template-rows: min-content 1fr min-content;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    height: 100%;
  }
`;

const Top = styled.div`
  display: grid;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding: 1.6rem 1.6rem 0 1.6rem;
  }
`;

const ProfileInfo = styled.div`
  display: none;
  position: relative;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    width: 100%;
    height: 4.8rem;
    background-color: ${({ theme }) => theme.white};
    border-radius: 4px;
    display: grid;
    align-items: center;
    gap: 0.4rem;
    padding: 0;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    height: 5.6rem;
    padding: 0 0.4rem 0 1.4rem;
    grid-template-columns: 1fr min-content min-content;
    justify-items: start;
  }
`;

const ImpersonationIndicator = styled.div`
  display: none;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: block;
    position: absolute;
    width: 100%;
    height: 0.8rem;
    left: 0;
    bottom: 0;
    ${({ theme }) => css`
      background: linear-gradient(
        135deg,
        ${theme.gradient.sunset[0]} 0%,
        ${theme.gradient.sunset[1]} 100%
      );
    `}
    border-radius: 0 0 4px 4px;
    pointer-events: none;
  }
`;

const ProfileLink = styled(Link)`
  height: 4.8rem;
  width: 4.8rem;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: min-content 1fr;
  gap: 0.9rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    width: unset;
    height: 5.6rem;
    grid-template-columns: min-content 1fr;
  }
`;

const Name = styled.p`
  font-size: 1.4rem;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  line-height: 1.2;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: -webkit-box;
  }
`;

const StyledBasicButton = styled(BasicButton)`
  padding: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: grid;
  }
`;

const NavContainer = styled.div`
  display: grid;
  overflow-y: auto;
  padding: 1.6rem 1.6rem 0 1.6rem;
  align-content: flex-start;
`;

const TopNavWrapper = styled.div`
  display: grid;
  padding-bottom: 1.6rem;
`;

const NavWrapper = styled.div`
  display: grid;
  padding-bottom: 2rem;
`;

const NavTitle = styled.h3`
  font-weight: 600;
  letter-spacing: 0.2rem;
  color: ${({ theme }) => theme.dark200};
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 0.4rem 0 0.6rem 0.8rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: block;
  }
`;

const SubNavWrapper = styled(NavWrapper)`
  gap: 1rem;
`;

// Lack of focus style is currently intentional to stay in sync with other menu items.
const NotificationButtonAsNavItem = styled.button`
  align-items: stretch;
  background: none;
  border: 0.2rem solid transparent;
  color: red;
  cursor: pointer;
  display: flex;
  height: 4rem;
  padding: 0;

  & > div {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex: 1;
    justify-content: center;
  }

  &:hover > div {
    background-color: ${({ theme }) => theme.dark600};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${({ theme }) => theme.dark300};
  margin-bottom: 2rem;
`;

const OpenModalButton = styled(Button)`
  display: grid;
  margin-top: 1.6rem;

  ${({ $small }) =>
    $small &&
    css`
      padding: 0;
    `}
`;

const AliasButton = styled(Button)`
  display: grid;
  grid-template-columns: 1fr min-content;
  margin-bottom: 1.6rem;
  text-align: left;
  color: ${({ theme }) => theme.sweetOrange};
  font-size: 1.4rem;

  ${({ isLoading }) =>
    isLoading &&
    css`
      grid-template-columns: min-content;
      align-items: center;
      justify-content: center;
    `}

  @media only screen and (min-width: ${({ theme }) =>
    theme.breakpoints.medium}px) {
    padding: 0;
    justify-items: center;

    & p {
      display: none;
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding: 0 2rem;
    justify-items: start;

    & p {
      display: block;
    }
  }
`;

const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  padding: 0 1.6rem 1.6rem 1.6rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: block;
  }
`;

const StyledLogo = styled(Logo)`
  margin-bottom: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: block;
  }
`;

const footerItemStyle = css`
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  gap: 1rem;
  padding-top: 1.4rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    grid-template-columns: min-content 1fr;
  }
`;

const FooterLink = styled.a`
  ${footerItemStyle}
`;

const FooterButton = styled(BasicButton)`
  ${footerItemStyle}
`;

const FooterText = styled.p`
  color: ${({ theme }) => theme.dark200};
  font-size: 1.4rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: block;
  }
`;

const PlayerButton = styled(Button)`
  height: 3.8rem;
  display: grid;
  border-width: 1px;
  white-space: nowrap;
  color: ${({ theme }) => theme.dark100};
  border: none;
  border-radius: 2.4rem;
  grid-template-columns: min-content 1fr;
  padding-left: 1rem;
  background: ${({ theme }) => theme.dark600};

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding: 0 1.2rem;
    `}

  &:hover {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.waterBlue};

    & div {
      border-color: ${({ theme }) => theme.white};
    }
    & svg {
      color: ${({ theme }) => theme.white};
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    padding-left: 0;
    background: unset;
    grid-template-columns: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    padding-left: 1rem;
    background: ${({ theme }) => theme.dark600};
    grid-template-columns: min-content 1fr;
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.waterBlue};
  min-height: 2.8rem;
  min-width: 2.8rem;
  margin-right: 1rem;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    margin-right: unset;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    margin-right: 1rem;
  }
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.15rem;
`;

const PlayButtonText = styled.p`
  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.medium}px) {
    display: none;
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.extraLarge}px) {
    display: block;
  }
`;

// TODO
export const SideBar = ({
  isSideBarDrawerOpen,
  isNotificationsOpen,
  setNotificationsOpen,
  hasNotifications,
  setHasNotifications,
  onNavigationClick,
}) => {
  const { t } = useTranslation();

  const { show, hide } = useIntercom();
  const [isOpen, setIsOpen] = useState(false);

  const { user: userInfo, logoutImpersonation } = useAuth();

  const impersonationId = Boolean(userInfo.impersonatorId);
  const { isWiderThanMedium, isNarrowerThanExtraLarge } = useMediaSize();

  const [createLeadModalIsOpen, toggleCreateLeadModal] = useToggle(false);
  const [logContactModalIsOpen, toggleLogContactModal] = useToggle(false);
  const [scheduleCallModalIsOpen, toggleScheduleCallModal] = useToggle(false);

  const isOwner = hasAllRequiredPermissions(userInfo, OWNER_ROLE_PERMISSIONS);
  const isAccountant = hasAllRequiredPermissions(
    userInfo,
    ACCOUNTANT_ROLE_PERMISSIONS
  );
  const isSupport = hasAllRequiredPermissions(
    userInfo,
    SUPPORT_ROLE_PERMISSIONS
  );
  const isPartner = hasAllRequiredPermissions(
    userInfo,
    PARTNER_ROLE_PERMISSIONS
  );
  const isPartnerManager = hasAllRequiredPermissions(
    userInfo,
    PARTNER_MANAGER_ROLE_PERMISSIONS
  );
  const isExperimental = hasAllRequiredPermissions(
    userInfo,
    EXPERIMENTAL_ROLE_PERMISSIONS
  );

  const isNormalUser =
    isOwner && ((isSupport && !!impersonationId) || !isSupport);

  const openModal = useCallback(() => {
    if (impersonationId) {
      toggleLogContactModal();
    } else {
      toggleCreateLeadModal();
    }
  }, [impersonationId, toggleCreateLeadModal, toggleLogContactModal]);

  const openScheduleCallModal = useCallback(() => {
    toggleScheduleCallModal();
  }, [toggleScheduleCallModal]);

  const toggleNotificationsPopup = useCallback(() => {
    setNotificationsOpen((prev) => !prev);
  }, [setNotificationsOpen]);

  const closeNotificationsPopup = useCallback(() => {
    setNotificationsOpen(false);
  }, [setNotificationsOpen]);

  const handleToggleIntercom = useCallback(() => {
    if (isOpen) {
      hide();
      setIsOpen(false);
      return;
    }

    show();
    setIsOpen(true);
  }, [hide, isOpen, show]);

  const handleOpenPlayer = useCallback(() => {
    window.open('https://player.tunify.com/', '_blank', 'noopener noreferrer');
  }, []);

  return (
    <Wrapper
      $isOpen={isSideBarDrawerOpen}
      $appVersion={config.SHOW_APP_VERSION}
    >
      <Container>
        <Top>
          <ProfileInfo>
            <ProfileLink to="/profile">
              {impersonationId ? <ProfileImpersonationIcon /> : <ProfileIcon />}

              <Name>{`${userInfo.firstName} ${userInfo.lastName}`}</Name>
            </ProfileLink>
            {hasNotifications ? (
              <StyledBasicButton onClick={toggleNotificationsPopup}>
                <AlertActiveIcon width="1.9rem" height="1.9rem" />
              </StyledBasicButton>
            ) : null}
            {isSupport ? <ShareImpersonationLink /> : null}
            {!!impersonationId ? <ImpersonationIndicator /> : null}
          </ProfileInfo>
        </Top>
        <NavContainer>
          {!!impersonationId ? (
            <AliasButton
              onClick={logoutImpersonation}
              size="medium"
              highlightColor="dark600"
            >
              <p>{t('common:button.logoutAlias')}</p>
              <LogOutIcon
                width="1.4rem"
                height="1.4rem"
                color={theme.sweetOrange}
              />
            </AliasButton>
          ) : null}

          {hasNotifications && isWiderThanMedium && isNarrowerThanExtraLarge ? (
            <NotificationButtonAsNavItem type="button" onClick={toggleNotificationsPopup}>
              <div>
                <AlertActiveIcon2 width="2.4rem" height="2.4rem" />
              </div>
            </NotificationButtonAsNavItem>
          ) : null}

          <TopNavWrapper>
            {isSupport ? (
              <>
                <MainNavItem
                  to="/customer-success/search"
                  Icon={SearchIcon}
                  text={t('customerSuccess:search.title')}
                  title={t('customerSuccess:search.navTitle')}
                  $navGradientTheme="nightfall"
                  onClick={onNavigationClick}
                />
                <MainNavItem
                  to="/customer-success/scheduled-calls"
                  Icon={ScheduledCallsIcon}
                  text={t('customerSuccess:scheduledCalls.title')}
                  title={t('customerSuccess:scheduledCalls.navTitle')}
                  $navGradientTheme="clearsky"
                  onClick={onNavigationClick}
                />
                <MainNavItem
                  to="/customer-success/history"
                  Icon={HistoryIcon}
                  text={t('customerSuccess:history.title')}
                  title={t('customerSuccess:history.navTitle')}
                  $navGradientTheme="sunset"
                  onClick={onNavigationClick}
                />
              </>
            ) : null}

            {isPartner || isPartnerManager ? (
              <>
                <MainNavItem
                  to="/partner-manager"
                  Icon={PartnerManagementIcon}
                  show={isPartnerManager}
                  text={t('partnerManager:partners.newTitle')}
                  title={t('partnerManager:partners.newNavTitle')}
                  $navGradientTheme="blooming"
                  onClick={onNavigationClick}
                />
                <MainNavItem
                  to="/partner"
                  Icon={PartnerIcon}
                  show={isPartner}
                  text={t('partner:dashboard.newTitle')}
                  title={t('partner:dashboard.newNavTitle')}
                  $navGradientTheme="spring"
                  onClick={onNavigationClick}
                />
              </>
            ) : null}

            {isSupport ? (
              <>
                {impersonationId && (
                  <OpenModalButton
                    highlightGradient="clearsky"
                    size="medium"
                    onClick={openScheduleCallModal}
                    $small={isWiderThanMedium && isNarrowerThanExtraLarge}
                    title={t('common:button.scheduleCall')}
                  >
                    {isWiderThanMedium && isNarrowerThanExtraLarge ? (
                      <AddIcon
                        color={theme.white}
                        width="1.4rem"
                        height="1.4rem"
                      />
                    ) : (
                      t('common:button:scheduleCall')
                    )}
                  </OpenModalButton>
                )}
                <OpenModalButton
                  highlightGradient={impersonationId ? 'sunset' : 'clearsky'}
                  size="medium"
                  onClick={openModal}
                  $small={isWiderThanMedium && isNarrowerThanExtraLarge}
                  title={
                    impersonationId
                      ? t('common:button.title.logContact')
                      : t('common:button.title.create', {
                          value: t('common:button.title.lead'),
                        })
                  }
                >
                  {isWiderThanMedium && isNarrowerThanExtraLarge ? (
                    <AddIcon
                      color={theme.white}
                      width="1.4rem"
                      height="1.4rem"
                    />
                  ) : impersonationId ? (
                    t('common:button.logContact')
                  ) : (
                    t('common:button.create', {
                      value: t('common:button.lead'),
                    })
                  )}
                </OpenModalButton>
              </>
            ) : null}
          </TopNavWrapper>
          <NavWrapper>
            {isNormalUser ||
            (isAccountant &&
              ((isSupport && !!impersonationId) || !isSupport)) ? (
              <>
                <MainNavItem
                  to="/"
                  Icon={!!impersonationId ? HomeImpersonationIcon : HomeIcon}
                  show={isNormalUser}
                  text={t('dashboard:home.title')}
                  title={t('dashboard:home.navTitle')}
                  $navGradientTheme={!!impersonationId ? 'sunset' : 'nightfall'}
                  onClick={onNavigationClick}
                />
                <MainNavItem
                  to="/dashboard"
                  end
                  Icon={
                    !!impersonationId
                      ? DashboardImpersonationIcon
                      : DashboardIcon
                  }
                  show={isNormalUser}
                  text={t('dashboard:title')}
                  title={t('dashboard:navTitle')}
                  $navGradientTheme={!!impersonationId ? 'sunset' : 'clearsky'}
                  onClick={onNavigationClick}
                />
                <MainNavItem
                  to="/invoices"
                  Icon={InvoicesIcon}
                  show={
                    isAccountant &&
                    ((isSupport && !!impersonationId) || !isSupport)
                  }
                  text={t('invoices:title')}
                  title={t('invoices:navTitle')}
                  $navGradientTheme="sunset"
                  onClick={onNavigationClick}
                />
                <MainNavItem
                  to="/gifts"
                  Icon={!!impersonationId ? GiftsImpersonationIcon : GiftsIcon}
                  show={isNormalUser}
                  text={t('gifts:title')}
                  title={t('gifts:navTitle')}
                  $navGradientTheme={!!impersonationId ? 'sunset' : 'blooming'}
                  onClick={onNavigationClick}
                />
              </>
            ) : null}
          </NavWrapper>

          {isNormalUser ? <Divider /> : null}
          {isNormalUser ? (
            <>
              <SubNavWrapper>
                <NavTitle>{t('common:button.music')}</NavTitle>
                <PlayerButton
                  emphasis="secondary"
                  size="small"
                  title={capitalize(
                    t('common:button.title.goTo', {
                      value: t('common:button.title.player'),
                    })
                  )}
                  onClick={handleOpenPlayer}
                >
                  <Circle>
                    <PlayIcon color={theme.waterBlue} />
                  </Circle>
                  <PlayButtonText>
                    {t('common:button.goToPlayer')}
                  </PlayButtonText>
                </PlayerButton>
                <SubNavItem
                  to="/subscriptions"
                  Icon={SubscriptionsIcon}
                  text={t('subscriptions:title')}
                  title={t('subscriptions:navTitle')}
                  onClick={onNavigationClick}
                />
                <ExperimentalWrapper experimentalKey="USE_EXPERIMENTAL_MUSIC_MANAGEMENT_MODULE">
                  <SubNavItem
                    to="/music-management"
                    Icon={MusicManagementIcon}
                    show={isExperimental}
                    text={t('musicManagement:title')}
                    title={t('musicManagement:navTitle')}
                    onClick={onNavigationClick}
                  />
                </ExperimentalWrapper>
                <SubNavItem
                  to="/add-ons"
                  Icon={AddonsIcon}
                  text={t('addons:title')}
                  title={t('addons:navTitle')}
                  onClick={onNavigationClick}
                />
                <SubNavItem
                  to="/broadcasts"
                  Icon={BroadcastIcon}
                  text={t('broadcasts:title')}
                  title={t('broadcasts:navTitle')}
                  onClick={onNavigationClick}
                />
                <SubNavItem
                  to="/playlists"
                  Icon={MusicManagementPlaylistsIcon}
                  text={t('musicManagement:playlists.title')}
                  title={t('musicManagement:playlists.navTitle')}
                  onClick={onNavigationClick}
                />
                <SubNavItem
                  to="/music-report"
                  Icon={MusicReportIcon}
                  text={t('musicManagement:musicReport.title')}
                  title={t('musicManagement:musicReport.navTitle')}
                  onClick={onNavigationClick}
                />
              </SubNavWrapper>
            </>
          ) : null}
        </NavContainer>
        <Footer>
          <Divider />
          <StyledLogo width="10rem" height="3.5rem" />
          <FooterLink href="tel:080017017" title="callto:080017017">
            <PhoneIcon
              width="1.6rem"
              height="1.6rem"
              color={theme.sweetOrange}
            />
            <FooterText>0800 17 017</FooterText>
          </FooterLink>
          <FooterLink
            href={`mailto:${t('config:customerSupportEmail')}`}
            title={`mailto:${t('config:customerSupportEmail')}`}
          >
            <MailIcon width="1.6rem" height="1.6rem" color={theme.babyBlue} />
            <FooterText>{t('config:customerSupportEmail')}</FooterText>
          </FooterLink>
          <FooterButton onClick={handleToggleIntercom}>
            <ChatIcon width="1.6rem" height="1.6rem" color={theme.mintGreen} />
            <FooterText>{t('global:menu.chat')}</FooterText>
          </FooterButton>
        </Footer>
        <NotificationsPopup
          isPopup={isWiderThanMedium && isNarrowerThanExtraLarge}
          isOpen={isNotificationsOpen}
          onClose={closeNotificationsPopup}
          setHasNotifications={setHasNotifications}
        />
      </Container>
      {isSupport ? (
        <>
          {createLeadModalIsOpen && (
            <CreateLeadModal
              isOpen={createLeadModalIsOpen}
              toggle={toggleCreateLeadModal}
            />
          )}
          {logContactModalIsOpen && (
            <LogContactModal
              isOpen={logContactModalIsOpen}
              toggle={toggleLogContactModal}
            />
          )}
          {scheduleCallModalIsOpen && (
            <ScheduleCallModal
              isOpen={scheduleCallModalIsOpen}
              toggle={toggleScheduleCallModal}
            />
          )}
        </>
      ) : null}
    </Wrapper>
  );
};
