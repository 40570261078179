import { ApolloProvider } from '@apollo/react-hooks';
import React, { Suspense, useMemo } from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from 'styled-components';

import { useAuth0 } from '@auth0/auth0-react';
import CheckServiceWorkerUpdateReady from './checkServiceWorkerUpdateReady';
import { ErrorBoundary, SuspenseLoadingIndicator } from './common';
import config from './config';
import CoviewScript from './coviewScript';
import { CustomerReferralProvider } from './global/customerReferral';
import { GlobalStateProvider } from './global/state';
import GlobalStyles, { theme } from './global/style';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// window.fetch polyfill for consistent x-browser and x-device behaviour
import { Auth0Provider } from '@auth0/auth0-react';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink, from, Observable } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import 'whatwg-fetch';
import { AuthProvider, useAuth } from './global/auth/newAuthProvider';
import { AppEntryPoint } from './refactor/appEntry';
import { RootLayoutBehindAuthentication } from './refactor/root/router';

import {
  QueryClient,
  QueryClientProvider,
  useQuery as useTanstackQuery,
} from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import LoadingPage from './global/application/loadingPage';
import { normalizeLangCookieValue } from './global/application/utils';
import { getLangCookie } from './modules/auth/register/langCookie';
import { LoginLikeLayout } from './modules/loginLikeLayout';
import { LandingPage } from './modules/loginLikeLayout/landing';
import { OnBoardingRegistration } from './modules/loginLikeLayout/registration';

import './global/i18n/initializeI18n';
import IntegrationLayout from './refactor/integrations';
import { integrationRoutesWithoutAuthentication } from './refactor/integrations/router';

const {
  GOOGLE_TAG_MANAGER_ID,
  ENABLE_GTM,
  BASE_URL,
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
} = config;

if (ENABLE_GTM) {
  TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
}

const INTERCOM_APP_ID = config.INTERCOM_APP_ID;
const INTERCOM_SHOULD_INITIALIZE = config.INTERCOM_SHOULD_INITIALIZE;

const logErrorLink = onError(({ graphQLErrors, networkError }) => {
  // When there are graphqlErrors (these are backend errors), log them to the console.
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
  // If there's a networkError log it to the console.
  if (networkError) {
    console.log(networkError);
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${networkError}`);
  }
});

// The default requestLink.
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      try {
        // This handle is what your Query uses to observe handled values.
        // Complete --> completed request,
        // next is a request for data an
        // error implies an errored request.
        handle = forward(operation).subscribe({
          complete: observer.complete.bind(observer),
          error: observer.error.bind(observer),
          next: observer.next.bind(observer),
        });
      } catch (e) {
        observer.error.bind(observer);
      }
      return () => {
        handle?.unsubscribe();
      };
    })
);

const httpLink = createUploadLink({
  credentials: 'same-origin',
  uri: config.API_BASE_URL,
});

const { LANGUAGE_COOKIE_KEY } = config;

const AppApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0();

  const apolloClient = React.useMemo(() => {
    if (isLoading) return undefined;

    const authLink = setContext(async (_, { headers }) => {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently({ cacheMode: true });

        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
            lang: Cookies.get(LANGUAGE_COOKIE_KEY),
            ...(sessionStorage.getItem('impersonationId')
              ? { impersonation: sessionStorage.getItem('impersonationId') }
              : {}),
          },
        };
      }
      return {
        headers: {
          ...headers,
          lang: Cookies.get(LANGUAGE_COOKIE_KEY),
        },
      };
    });

    return new ApolloClient({
      link: from([authLink, logErrorLink, requestLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);

  if (isLoading) return <LoadingPage />;

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const PublicAppAuthorizationGuard = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return children;
};

const OnBoardingRegistrationGuard = () => {
  const { login, user } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!searchParams.get('userId')) {
      login({
        authorizationParams: {
          screen_hint: 'signup',
          mode: 'signUp',
        }
      });
      return;
    }

    if (user) {
      navigate('/');
    }
  }, [login, navigate, searchParams, user]);

  return <Outlet />;
};

const AppAuthorizationGuard = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  return <Outlet />;
};

const IntercomProviderWrapper = ({ children }) => {
  const { user } = useAuth();

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      shouldInitialize={INTERCOM_SHOULD_INITIALIZE}
      autoBoot
      autoBootProps={{ ...user?.intercom_user }}
    >
      {children}
    </IntercomProvider>
  );
};

const TUNIFY_TO_AUTH0_LOCALE_MAPPER = {
  'nl-be': 'nl',
  'nl-nl': 'nl',
  'fr-be': 'fr-FR',
  'de-de': 'de',
  'en-gb': 'en',
};

const queryClient = new QueryClient();

const Auth0ProverWrapper = ({ children }) => {
  const { i18n } = useTranslation();

  const { data } = useTanstackQuery({
    queryKey: ['language'],
    queryFn: async () => await getLangCookie(),
    enabled: Cookies.get(LANGUAGE_COOKIE_KEY) === undefined,
  });

  const authorizationParams = React.useMemo(() => {
    const locales = (() => {
      // If the language is undefined then the query will be enabled and thus fetch. Otherwise the cookie already has been set so just return it.
      if (Cookies.get(LANGUAGE_COOKIE_KEY) === undefined) return data;

      return Cookies.get(LANGUAGE_COOKIE_KEY);
    })();

    return {
      redirect_uri: BASE_URL,
      audience: AUTH0_AUDIENCE,
      ui_locales: TUNIFY_TO_AUTH0_LOCALE_MAPPER[locales] ?? 'nl',
      tunify_locales: locales,
    };
  }, [data]);

  React.useEffect(() => {
    (async () => {
      if (!Cookies.get(LANGUAGE_COOKIE_KEY)) return;

      await i18n.changeLanguage(
        normalizeLangCookieValue(Cookies.get(LANGUAGE_COOKIE_KEY))
      );
    })();
  }, [data, i18n]);

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      authorizationParams={authorizationParams}
    >
      {children}
    </Auth0Provider>
  );
};

const I18nWrapper = ({ children }) => {
  const { ready } = useTranslation();

  if (!ready) {
    return <LoadingPage />;
  }

  return children;
};

const NavigateWithoutPrefix = ({ prefix }) => {
  const location = useLocation();
  const to = useMemo(
    () => ({
      pathname: location.pathname.substring(prefix.length),
      search: location.search,
      hash: location.hash
    }),
    [location, prefix.length]
  );
  return <Navigate to={to} />;
};

ReactDOM.render(
  <React.StrictMode>
    {process.env.NODE_ENV === 'production' ? <CoviewScript /> : null}
    <GlobalStyles />
    <ThemeProvider theme={theme}>
      <ErrorBoundary root>
        <BrowserRouter>
          <CheckServiceWorkerUpdateReady />
          <CookiesProvider>
            <QueryClientProvider client={queryClient}>
              <Auth0ProverWrapper>
                <IntercomProviderWrapper>
                  <CustomerReferralProvider>
                    <AppApolloProvider>
                      <AuthProvider>
                        <HelmetProvider>
                          <GlobalStateProvider>
                            <Suspense
                              fallback={<SuspenseLoadingIndicator root />}
                            >
                              <I18nWrapper>
                                <Routes>
                                  <Route
                                    path="/"
                                    element={<AppAuthorizationGuard />}
                                  >
                                    <Route path="/" element={<AppEntryPoint />}>
                                      {RootLayoutBehindAuthentication}
                                    </Route>
                                  </Route>
                                  <Route
                                    path="/onboard"
                                    element={<LoginLikeLayout />}
                                  >
                                    <Route
                                      path="invite"
                                      element={
                                        <PublicAppAuthorizationGuard>
                                          <LandingPage />
                                        </PublicAppAuthorizationGuard>
                                      }
                                    />
                                    <Route
                                      path="registration"
                                      element={<OnBoardingRegistrationGuard />}
                                    >
                                      <Route
                                        index
                                        element={<OnBoardingRegistration />}
                                      />
                                    </Route>
                                  </Route>
                                  <Route
                                    path="/integrations"
                                    element={<IntegrationLayout />}
                                  >
                                    {integrationRoutesWithoutAuthentication}
                                  </Route>
                                  {/* Legacy support. */}
                                  <Route
                                    path="login"
                                    element={<Navigate to="/" />}
                                  />
                                  <Route
                                    path="register"
                                    element={<Navigate to="/onboard/registration" />}
                                  />
                                  {/* Deprecated /dashboard routes. */}
                                  <Route
                                    path="/dashboard/*"
                                    element={<NavigateWithoutPrefix prefix="/dashboard" />}
                                  />
                                </Routes>
                              </I18nWrapper>
                            </Suspense>
                          </GlobalStateProvider>
                        </HelmetProvider>
                      </AuthProvider>
                    </AppApolloProvider>
                  </CustomerReferralProvider>
                </IntercomProviderWrapper>
              </Auth0ProverWrapper>
            </QueryClientProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    window.swUpdateReady = true;
    console.log(
      'Skip waiting for new content to be used, reload on route change is activated.'
    );
  },
});
