import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';

import { Button, capitalize } from '../../../common';
import theme from '../../../global/style/theme';
import { ReactComponent as RawPlayIcon } from '../../../assets/icons/simplePlayControls/play.svg';
import { GET_PLAYER_LINK } from './api';
import { usePlaylists } from './playlistsProvider';

const Container = styled.div`
  padding: 2rem;
  text-align: center;
`;

const Description = styled.p`
  color: ${({ theme }) => theme.dark400};
  font-family: 'Inter', sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.429;
  margin-bottom: 2rem;
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.waterBlue};
  margin-right: 1rem;
  min-height: 2.8rem;
  min-width: 2.8rem;
`;

const PlayIcon = styled(RawPlayIcon)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.15rem;
`;

const PlayerButton = styled(Button)`
  height: 3.8rem;
  padding-left: 0.4rem;
  display: grid;
  grid-template-columns: min-content 1fr;
  border-width: 1px;
  white-space: nowrap;
  color: ${({ theme }) => theme.dark300};
  border-color: ${({ theme }) => theme.dark300};

  ${({ isLoading }) =>
    isLoading &&
    css`
      padding: 0 1.2rem;
    `}

  &:hover {
    border-width: 1px;
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.dark100};
  }
`;

const StartPlayer = () => {
  const { t } = useTranslation();
  const { selectedZone } = usePlaylists();

  const [getPlayerLink, { loading }] = useLazyQuery(GET_PLAYER_LINK, {
    variables: { zoneId: Number(selectedZone.id) },
    onCompleted: ({ playerLink }) => {
      window.open(playerLink, '_blank', 'noopener noreferrer');
    },
    fetchPolicy: 'network-only',
  });

  return (
    <Container>
      <Description>
        {t('musicManagement:playlist.startPlayer.description')}
      </Description>
      <PlayerButton
        emphasis="outline"
        size="medium"
        title={capitalize(
          t('common:button.title.goTo', {
            value: t('common:button.title.player'),
          })
        )}
        onClick={() => getPlayerLink()}
        isLoading={loading}
      >
        <Circle>
          <PlayIcon color={theme.waterBlue} />
        </Circle>
        {t('common:button.goToPlayer')}
      </PlayerButton>
    </Container>
  );
};

export default StartPlayer;
