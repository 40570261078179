import { useMemo, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { capitalize } from '..';
import LoadingIndicator from '../loadingIndicator';
import BasicButton from './basicButton';

import buttonTypes from './buttonTypes';
import sizes from './sizes';

import { ReactComponent as SuccessIcon } from '../../assets/icons/smallCheckmark.svg';
import { ReactComponent as FailIcon } from '../../assets/icons/close.svg';
import theme from '../../global/style/theme';

const Container = styled(BasicButton)`
  position: relative;
  z-index: 1;
  transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s border-color ease-in-out;

  ${({ $size, $emphasis }) => sizes[$emphasis][$size]}

  ${({ $emphasis }) => buttonTypes[$emphasis]}

  ${({ $isFail, theme }) =>
    $isFail &&
    css`
      background: ${theme.grapefruitRed};
      pointer-events: none;
    `}

    ${({ $isSuccess, theme }) =>
    $isSuccess &&
    css`
      background: ${theme.plantGreen};
      pointer-events: none;
    `}

  ${({ $shouldCapitalizeText }) =>
    $shouldCapitalizeText &&
    css`
      text-transform: capitalize;
    `}
`;

const StyledLoadingIndicator = styled(LoadingIndicator)`
  transform: scale(0.8);
`;

const BUTTON_SUCCESS_TIMEOUT = 3000;

const Button = ({
  emphasis = 'primary',
  highlightColor,
  highlightGradient = 'nightfall',
  size = 'large',
  defaultActionColors = false,
  onClick,
  isLoading = false,
  isDisabled = false,
  isSuccess = false,
  isFail = false,
  type = 'button',
  className,
  children,
  shouldCapitalizeText = false,
  title = '',
  value,
  isDiv,
}) => {
  const [isLocalSuccess, setIsLocalSuccess] = useState(isSuccess);
  const [isLocalFail, setIsLocalFail] = useState(isFail);

  useEffect(() => {
    setIsLocalSuccess(isSuccess);
    setIsLocalFail(isFail);
    if (isSuccess) setTimeout(() => setIsLocalSuccess(false), BUTTON_SUCCESS_TIMEOUT);
    if (isFail) setTimeout(() => setIsLocalFail(false), BUTTON_SUCCESS_TIMEOUT);
  }, [isSuccess, isFail]);

  const memoizedIsDisabled = useMemo(() => isDisabled || isLoading, [isDisabled, isLoading]);

  const content = useMemo(() => {
    if (emphasis === 'outline') {
      if (isLoading) return <StyledLoadingIndicator size={size} />;
      if (isLocalSuccess) return <SuccessIcon color={theme.dark500} width="1.5rem" height="1.5rem" />;
      if (isLocalFail) return <FailIcon color={theme.dark500} width="1.4rem" height="1.4rem" />;
    }
    if (emphasis !== 'secondary') {
      if (isLoading) return <StyledLoadingIndicator size={size} color={!defaultActionColors ? 'white' : undefined} />;
      if (isLocalSuccess)
        return <SuccessIcon color={!defaultActionColors ? 'white' : theme.dark500} width="1.5rem" height="1.5rem" />;
      if (isLocalFail)
        return <FailIcon color={!defaultActionColors ? 'white' : theme.dark500} width="1.4rem" height="1.4rem" />;
    }
    return <>{typeof children === 'string' ? <p>{children}</p> : children}</>;
  }, [isLoading, isLocalSuccess, isLocalFail, children, size, emphasis, defaultActionColors]);

  return (
    <Container
      $emphasis={emphasis}
      $highlightColor={highlightColor}
      $highlightGradient={highlightGradient}
      $shouldCapitalizeText={shouldCapitalizeText}
      $size={size}
      $isFail={isLocalFail}
      $isSuccess={isLocalSuccess}
      className={className}
      disabled={memoizedIsDisabled}
      onClick={!isLoading ? onClick : undefined}
      title={capitalize(title)}
      type={type}
      value={value}
      isDiv={isDiv}
    >
      {content}
    </Container>
  );
};

export default Button;
