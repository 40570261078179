import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { GET_USER_BY_ID } from '../../modules/profile/api';
import LoadingPage from '../application/loadingPage';

/**
 * @typedef {Object} User
 * @property { number } id
 * @property {string} firstName
 * @property { string } lastName
 * @property { string } emailAddress
 * @property { string } intercomUserHash
 * @property { string } isChainCustomer
 * @property { boolean } isRegistrationComplete
 * @property { boolean } is_social
 * @property { string } lang
 * @property { string[] } permissions
 * @property { string } contactType
 * @property { string } phone
 * @property { string } pictureUrl
 * @property { string } registrationCode
 * @property { string } registrationCountry
 * @property { string } registrationDate
 */

const AuthContext = React.createContext({
  login: () => {
    throw new Error('No yet implemented');
  },
  logout: () => {
    throw new Error('No yet implemented');
  },
  impersonate: () => {
    throw new Error('No yet implemented');
  },
  logoutImpersonation: () => {
    throw new Error('No yet implemented');
  },
  refetch: () => {
    throw new Error('No yet implemented');
  },

  /** @type {User} */
  user: undefined,
});

export const AuthProvider = ({ children }) => {
  const {
    user,
    loginWithRedirect,
    logout: auth0Logout,
    getAccessTokenSilently,
  } = useAuth0();

  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  const [impersonationId, setImpersonationId] = useState(
    window.sessionStorage.getItem('impersonationId')
  );

  const { loading, data } = useQuery(GET_USER_BY_ID, {
    variables: { odooId: impersonationId },
    skip: impersonationId == null,
  });

  const impersonate = React.useCallback(
    async (id, predefinedRoute) => {
      setImpersonationId(id);
      window.sessionStorage.setItem('impersonationId', id);
      await apolloClient.clearStore();

      if (!loading) {
        navigate(predefinedRoute ?? '/subscriptions');
      }
      // window.location.reload();
    },
    [apolloClient, loading, navigate]
  );

  const logoutImpersonation = React.useCallback(() => {
    sessionStorage.clear();
    navigate('/');
    window.location.reload();
  }, [navigate]);

  const logout = React.useCallback(() => {
    logoutImpersonation();
    auth0Logout();
  }, [auth0Logout, logoutImpersonation]);

  /**
   * @returns {User}
   */
  const appUser = React.useMemo(() => {
    const {
      intercomUserHash,
      isChainCustomer,
      isRegistrationComplete,
      permissions,
      contactType,
      pictureUrl,
      registrationCode,
      registrationCountry,
      registrationDate,
    } = user?.app_user ?? {};

    if (impersonationId && !loading && data?.getUserById) {
      return {
        id: data.getUserById.id,
        firstName: data.getUserById.firstName,
        lastName: data.getUserById.lastName,
        emailAddress: data.getUserById.emailAddress,
        intercomUserHash,
        isChainCustomer,
        isRegistrationComplete,
        is_social: data.getUserById.is_social,
        lang: data.getUserById.lang,
        permissions,
        contactType,
        phone: data.getUserById.phone,
        pictureUrl,
        registrationCode,
        registrationCountry,
        registrationDate,
        impersonatorId: String(user?.app_user.id),
      };
    }

    return user?.app_user ?? undefined;
  }, [data?.getUserById, impersonationId, loading, user?.app_user]);

  const providerValue = React.useMemo(
    () => ({
      login: loginWithRedirect,
      logout,
      user: appUser,
      impersonate,
      logoutImpersonation,
      refetch: getAccessTokenSilently,
    }),
    [
      appUser,
      getAccessTokenSilently,
      impersonate,
      loginWithRedirect,
      logout,
      logoutImpersonation,
    ]
  );

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};
